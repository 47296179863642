@charset "UTF-8";
@import url(base.css);
@import url(common.css);
/*---------------------------------
Page CSS 2018.02
CSS 担当者名 Document 2018.02
Last Update 2018.02 担当者名
---------------------------------*/
@media print, screen and (min-width: 768px) {
  /*
  404
  -------------------------------------*/
  /*
  トップページ 施設 共通  pc
  -------------------------------------*/
  #PageIndex.pageIndex #Main #ContBox02 .searchTitle,
  #PageFacility.pageIndex #Main #ContBox01 .searchTitle,
  #PageFacility.pageCategory #Main #ContBox01 .searchTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 8px;
    letter-spacing: 2px;
    color: #444;
    text-align: left; }
    #PageIndex.pageIndex #Main #ContBox02 .searchTitle span,
    #PageFacility.pageIndex #Main #ContBox01 .searchTitle span,
    #PageFacility.pageCategory #Main #ContBox01 .searchTitle span {
      color: #4391d3; }
  #PageIndex.pageIndex #Main #ContBox02 div.left,
  #PageFacility.pageIndex #Main #ContBox01 div.left,
  #PageFacility.pageCategory #Main #ContBox01 div.left {
    width: 320px;
    float: left; }
  #PageIndex.pageIndex #Main #ContBox02 div.right,
  #PageFacility.pageIndex #Main #ContBox01 div.right,
  #PageFacility.pageCategory #Main #ContBox01 div.right {
    width: 630px;
    float: right; }
  #PageIndex.pageIndex #Main #ContBox02 div .search,
  #PageFacility.pageIndex #Main #ContBox01 div .search,
  #PageFacility.pageCategory #Main #ContBox01 div .search {
    width: 100%;
    border: 3px solid #ededed;
    border-radius: 6px; }
    #PageIndex.pageIndex #Main #ContBox02 div .search ul li,
    #PageFacility.pageIndex #Main #ContBox01 div .search ul li,
    #PageFacility.pageCategory #Main #ContBox01 div .search ul li {
      width: 100%;
      padding: 11px 15px 13px 45px;
      background: url(../img/menu_arrow02.png) no-repeat top 19px left 16px;
      background-size: 19px auto; }
      #PageIndex.pageIndex #Main #ContBox02 div .search ul li + li,
      #PageFacility.pageIndex #Main #ContBox01 div .search ul li + li,
      #PageFacility.pageCategory #Main #ContBox01 div .search ul li + li {
        border-top: 1px solid #ebebeb; }
      #PageIndex.pageIndex #Main #ContBox02 div .search ul li a,
      #PageFacility.pageIndex #Main #ContBox01 div .search ul li a,
      #PageFacility.pageCategory #Main #ContBox01 div .search ul li a {
        font-size: 20px;
        line-height: 34px;
        color: #444;
        text-decoration: none; }
        #PageIndex.pageIndex #Main #ContBox02 div .search ul li a:hover,
        #PageFacility.pageIndex #Main #ContBox01 div .search ul li a:hover,
        #PageFacility.pageCategory #Main #ContBox01 div .search ul li a:hover {
          opacity: 0.8; }
      #PageIndex.pageIndex #Main #ContBox02 div .search ul li.current a, #PageIndex.pageIndex #Main #ContBox02 div .search ul li.current-cat a,
      #PageFacility.pageIndex #Main #ContBox01 div .search ul li.current a,
      #PageFacility.pageIndex #Main #ContBox01 div .search ul li.current-cat a,
      #PageFacility.pageCategory #Main #ContBox01 div .search ul li.current a,
      #PageFacility.pageCategory #Main #ContBox01 div .search ul li.current-cat a {
        color: #a3a3a3; }
  #PageIndex.pageIndex #Main #ContBox02 #ContSubBox01 .searchTitle,
  #PageFacility.pageIndex #Main #ContBox01 #ContSubBox01 .searchTitle,
  #PageFacility.pageCategory #Main #ContBox01 #ContSubBox01 .searchTitle {
    background: url(../img/index_icon01.png) no-repeat center left;
    background-size: 26px auto;
    padding-left: 35px; }
  #PageIndex.pageIndex #Main #ContBox02 #ContSubBox01 .link02,
  #PageFacility.pageIndex #Main #ContBox01 #ContSubBox01 .link02,
  #PageFacility.pageCategory #Main #ContBox01 #ContSubBox01 .link02 {
    margin: 16px 0 48px;
    letter-spacing: 1px; }
  #PageIndex.pageIndex #Main #ContBox02 #ContSubBox02 .searchTitle,
  #PageFacility.pageIndex #Main #ContBox01 #ContSubBox02 .searchTitle,
  #PageFacility.pageCategory #Main #ContBox01 #ContSubBox02 .searchTitle {
    background: url(../img/index_icon02.png) no-repeat center left;
    background-size: 26px auto;
    padding-left: 35px;
    letter-spacing: 0.5px; }
  #PageIndex.pageIndex #Main #ContBox02 #ContSubBox02 .search ul li,
  #PageFacility.pageIndex #Main #ContBox01 #ContSubBox02 .search ul li,
  #PageFacility.pageCategory #Main #ContBox01 #ContSubBox02 .search ul li {
    padding-left: 50px; }
  #PageIndex.pageIndex #Main #ContBox02 #ContSubBox03 .searchTitle,
  #PageFacility.pageIndex #Main #ContBox01 #ContSubBox03 .searchTitle,
  #PageFacility.pageCategory #Main #ContBox01 #ContSubBox03 .searchTitle {
    background: url(../img/index_icon03.png) no-repeat center left;
    background-size: 33px auto;
    padding-left: 40px; }
  #PageIndex.pageIndex #Main #ContBox02 #ContSubBox03 #Gmap01,
  #PageFacility.pageIndex #Main #ContBox01 #ContSubBox03 #Gmap01,
  #PageFacility.pageCategory #Main #ContBox01 #ContSubBox03 #Gmap01 {
    width: 100%;
    height: 490px; }
  #PageIndex.pageIndex #Main #ContBox02 #ContSubBox03 .mapName a,
  #PageFacility.pageIndex #Main #ContBox01 #ContSubBox03 .mapName a,
  #PageFacility.pageCategory #Main #ContBox01 #ContSubBox03 .mapName a {
    text-decoration: none;
    font-size: 14px; }
    #PageIndex.pageIndex #Main #ContBox02 #ContSubBox03 .mapName a:hover,
    #PageFacility.pageIndex #Main #ContBox01 #ContSubBox03 .mapName a:hover,
    #PageFacility.pageCategory #Main #ContBox01 #ContSubBox03 .mapName a:hover {
      text-decoration: underline; }
  /*
  トップページ pc
  -------------------------------------*/
  #PageIndex.pageIndex #Main {
    margin-bottom: 94px; }
    #PageIndex.pageIndex #Main #ContBox01 div.img {
      width: 180px;
      margin: 0 31px 0 80px;
      background: url(../img/index_iimg01.png) no-repeat top left;
      background-size: 180px auto;
      padding-top: 187px;
      text-align: center; }
    #PageIndex.pageIndex #Main #ContBox01 div.txt {
      width: 645px;
      padding-top: 20px;
      text-align: right; }
      #PageIndex.pageIndex #Main #ContBox01 div.txt dl {
        text-align: left; }
      #PageIndex.pageIndex #Main #ContBox01 div.txt .link {
        margin-top: 28px;
        margin-right: 7px; }
    #PageIndex.pageIndex #Main #ContBox02 {
      margin-top: 70px; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic:after {
        content: "";
        display: block;
        visibility: hidden;
        clear: both;
        height: 0; }
    #PageIndex.pageIndex #Main #ContBox03 {
      border-top: 1px solid #eee;
      margin-top: 100px;
      padding-top: 73px; }
      #PageIndex.pageIndex #Main #ContBox03 h2 {
        color: #444;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        background: url(../img/index_icon04.png) no-repeat center left;
        background-size: 32px auto;
        padding-left: 40px; }
      #PageIndex.pageIndex #Main #ContBox03 .title {
        position: relative; }
        #PageIndex.pageIndex #Main #ContBox03 .title .link {
          position: absolute;
          right: 0;
          top: 0;
          line-height: 40px; }
      #PageIndex.pageIndex #Main #ContBox03 ul.blogList {
        margin-top: 22px;
        font-size: 0; }
        #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li {
          width: 300px;
          display: inline-block;
          vertical-align: top; }
          #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li + li {
            margin-left: 50px; }
          #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li figure {
            width: 100%;
            height: 185px;
            overflow: hidden; }
            #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li figure a {
              display: block;
              width: 100%;
              height: 100%; }
              #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li figure a:hover {
                opacity: 0.8; }
            #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li figure img {
              width: 100%;
              height: auto; }
          #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl {
            margin-top: 20px; }
            #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dd {
              font-size: 16px;
              color: #a9905e; }
              #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dd ul {
                display: table;
                width: 100%; }
                #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dd ul li {
                  display: table-cell;
                  vertical-align: top;
                  line-height: 22px;
                  text-align: left; }
              #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dd .date {
                border-right: 1px solid #e1e0dd;
                padding-right: 10px;
                letter-spacing: 1px;
                width: 105px; }
              #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dd .category {
                padding-left: 12px; }
            #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dt {
              margin-top: 10px;
              font-size: 18px;
              line-height: 30px; }
              #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dt a {
                color: #444;
                text-decoration: none; }
                #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dt a:hover {
                  text-decoration: underline; }
  /*
  ご入居までの流れ pc
  -------------------------------------*/
  #PageInfo.pageIndex #Main #ContBox01 {
    margin-bottom: 95px; }
    #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol {
      list-style: none; }
      #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li {
        background: url(../img/contents/flow_line.png) repeat-x top left;
        background-size: 3px auto; }
        #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div {
          padding: 58px 0 60px 47px;
          position: relative; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div:before {
            content: "";
            display: block;
            width: 2px;
            height: 100%;
            background: #9cc4e7;
            position: absolute;
            left: 7px;
            top: 0;
            z-index: 5; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div > dl > dt {
            font-size: 40px;
            font-weight: bold;
            color: #4391d3;
            line-height: 44px;
            margin-bottom: 29px;
            position: relative;
            letter-spacing: 2px; }
            #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div > dl > dt:before {
              content: "";
              display: block;
              width: 16px;
              height: 16px;
              background: #9cc4e7;
              border-radius: 50%;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              left: -47px;
              z-index: 10; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div > dl > dd {
            font-size: 20px;
            line-height: 34px;
            padding-left: 4px; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo {
            margin-top: 20px; }
            #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li {
              vertical-align: top; }
            #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.tel {
              font-size: 50px; }
              #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.tel dl dt {
                font-size: 50px;
                background-size: 54px auto;
                padding-left: 60px; }
                #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.tel dl dt a {
                  color: #444;
                  cursor: default;
                  text-decoration: none; }
              #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.tel dl dd {
                font-size: 18px;
                text-align: center; }
            #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.contact {
              width: 360px;
              height: 74px;
              line-height: 74px;
              margin-left: 35px;
              letter-spacing: 1px;
              text-align: center; }
              #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.contact a {
                font-size: 26px; }
                #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.contact a:before {
                  width: 40px;
                  height: 74px;
                  background-size: 40px auto;
                  position: relative;
                  left: -7px; }
        #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:first-child > div {
          position: relative; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:first-child > div:after {
            content: "";
            display: block;
            width: 2px;
            height: 82px;
            background: #fff;
            position: absolute;
            left: 7px;
            top: 0;
            z-index: 8; }
        #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:nth-child(n+2) > div {
          min-height: 318px; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:nth-child(n+2) > div > dl {
            width: 680px; }
        #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:nth-child(2) > div {
          background: url(../img/contents/flow_img01.png) no-repeat right center;
          background-size: 238px auto; }
        #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:nth-child(3) > div {
          background: url(../img/contents/flow_img02.png) no-repeat right center;
          background-size: 238px auto; }
        #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:nth-child(4) > div {
          background: url(../img/contents/flow_img03.png) no-repeat right center;
          background-size: 238px auto; }
        #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:nth-child(5) > div {
          background: url(../img/contents/flow_img04.png) no-repeat right center;
          background-size: 238px auto; }
        #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:last-child {
          background: url(../img/contents/flow_line.png) repeat-x top left, url(../img/contents/flow_line.png) repeat-x bottom left;
          background-size: 3px auto; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:last-child > div {
            min-height: auto;
            padding-bottom: 53px; }
            #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:last-child > div:before {
              height: 82px; }
  /*
  施設 共通 pc
  -------------------------------------*/
  #PageFacility.pageIndex #Main,
  #PageFacility.pageCategory #Main {
    margin-bottom: 180px; }
    #PageFacility.pageIndex #Main #ContBox01:after,
    #PageFacility.pageCategory #Main #ContBox01:after {
      content: "";
      display: block;
      visibility: hidden;
      clear: both;
      height: 0; }
    #PageFacility.pageIndex #Main #ContBox02,
    #PageFacility.pageCategory #Main #ContBox02 {
      margin-top: 100px; }
      #PageFacility.pageIndex #Main #ContBox02 h3,
      #PageFacility.pageCategory #Main #ContBox02 h3 {
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 75px; }
      #PageFacility.pageIndex #Main #ContBox02 .newList,
      #PageFacility.pageCategory #Main #ContBox02 .newList {
        font-size: 0; }
        #PageFacility.pageIndex #Main #ContBox02 .newList li,
        #PageFacility.pageCategory #Main #ContBox02 .newList li {
          width: 475px;
          display: inline-block;
          vertical-align: top; }
          #PageFacility.pageIndex #Main #ContBox02 .newList li:nth-child(2n),
          #PageFacility.pageCategory #Main #ContBox02 .newList li:nth-child(2n) {
            margin-left: 50px; }
          #PageFacility.pageIndex #Main #ContBox02 .newList li:nth-child(n+3),
          #PageFacility.pageCategory #Main #ContBox02 .newList li:nth-child(n+3) {
            margin-top: 100px; }
          #PageFacility.pageIndex #Main #ContBox02 .newList li figure,
          #PageFacility.pageCategory #Main #ContBox02 .newList li figure {
            height: 290px;
            overflow: hidden; }
            #PageFacility.pageIndex #Main #ContBox02 .newList li figure a,
            #PageFacility.pageCategory #Main #ContBox02 .newList li figure a {
              display: block;
              width: 100%;
              height: 100%; }
              #PageFacility.pageIndex #Main #ContBox02 .newList li figure a:hover,
              #PageFacility.pageCategory #Main #ContBox02 .newList li figure a:hover {
                opacity: 0.8; }
            #PageFacility.pageIndex #Main #ContBox02 .newList li figure img,
            #PageFacility.pageCategory #Main #ContBox02 .newList li figure img {
              width: 100%;
              height: auto; }
          #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl,
          #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl {
            margin-top: 18px;
            color: #444; }
            #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.type,
            #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.type {
              font-size: 18px;
              line-height: 29px;
              font-weight: 700; }
            #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info,
            #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info {
              margin-top: 18px; }
              #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl,
              #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl {
                display: table;
                width: 100%;
                font-size: 18px;
                line-height: 30px;
                border-right: 1px solid #dfdfdf;
                border-left: 1px solid #dfdfdf; }
                #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl:first-of-type,
                #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl:first-of-type {
                  border-top: 1px solid #dfdfdf; }
                #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl:last-of-type,
                #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl:last-of-type {
                  border-bottom: 1px solid #dfdfdf; }
                #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl + dl,
                #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl + dl {
                  background: url(../img/contents/flow_line.png) repeat-x top left;
                  background-size: 3px auto; }
                  #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl + dl dt,
                  #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl + dl dt {
                    background: #f1f9fd url(../img/contents/flow_line.png) repeat-x top left;
                    background-size: 3px auto; }
                #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl dt, #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl dd,
                #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl dt,
                #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl dd {
                  display: table-cell;
                  vertical-align: top;
                  padding: 10px 20px; }
                #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl dt,
                #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl dt {
                  font-weight: 700;
                  background: #f1f9fd;
                  width: 150px; }
            #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dt,
            #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dt {
              font-size: 28px;
              line-height: 38px;
              font-weight: 700;
              letter-spacing: 1.2px; }
          #PageFacility.pageIndex #Main #ContBox02 .newList li > div div,
          #PageFacility.pageCategory #Main #ContBox02 .newList li > div div {
            width: 100%;
            height: 45px;
            line-height: 45px;
            margin-top: 20px; }
            #PageFacility.pageIndex #Main #ContBox02 .newList li > div div a,
            #PageFacility.pageCategory #Main #ContBox02 .newList li > div div a {
              display: block;
              width: 100%;
              height: 100%;
              border: 1px solid #6ea7d8;
              border-radius: 6px;
              background: url(../img/menu_arrow01.png) no-repeat center right 18px;
              background-size: 11px auto;
              color: #559ad3;
              font-size: 16px;
              text-align: center;
              text-decoration: none; }
              #PageFacility.pageIndex #Main #ContBox02 .newList li > div div a:hover,
              #PageFacility.pageCategory #Main #ContBox02 .newList li > div div a:hover {
                opacity: 0.8; }
  /*
  施設
  -------------------------------------*/
  /*
  施設カテゴリアーカイブ（子カテゴリ）
  -------------------------------------*/
  /*
  施設詳細 pc
  -------------------------------------*/
  #PageFacility.pageEntry #Main {
    margin-top: 95px;
    margin-bottom: 160px; }
    #PageFacility.pageEntry #Main #ContBox01 h2 {
      font-size: 50px;
      font-weight: 700;
      line-height: 62px;
      color: #444;
      border-bottom: 3px solid #ede6d9;
      padding-bottom: 20px;
      margin-top: 5px;
      letter-spacing: 2px; }
    #PageFacility.pageEntry #Main #ContBox01 span.type {
      font-size: 30px;
      font-weight: 700;
      line-height: 42px;
      color: #444;
      display: block;
      letter-spacing: 1.5px; }
    #PageFacility.pageEntry #Main #ContBox01 > ul {
      margin: 20px 0 40px;
      font-size: 0; }
      #PageFacility.pageEntry #Main #ContBox01 > ul li {
        display: inline-block;
        border: 1px solid #dce5ec;
        border-radius: 3px;
        background: #e9f4fe;
        color: #4391d3;
        font-size: 14px;
        font-weight: 700;
        padding: 0 4px;
        height: 24px;
        line-height: 24px;
        margin-right: 8px;
        letter-spacing: 1px; }
    #PageFacility.pageEntry #Main #ContBox01 .contSubBox {
      margin-top: 60px; }
    #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 {
      margin-top: 33px; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01:after {
        content: "";
        display: block;
        visibility: hidden;
        clear: both;
        height: 0; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock {
        width: 560px;
        float: left;
        font-size: 0; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock img {
          width: auto;
          height: 100%; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock ul {
          display: inline-block;
          vertical-align: top;
          width: 60px; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock ul li {
            width: 100%;
            height: 60px;
            overflow: hidden;
            cursor: pointer; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock ul li + li {
              margin-top: 2px; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock ul li a {
              display: block;
              width: 100%;
              height: 100%; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock ul li img {
              width: auto;
              height: 100%; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock ul li.current {
              opacity: 0.2; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock ul li:hover {
              opacity: 0.2; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock .mainImg {
          display: inline-block;
          vertical-align: top;
          width: 496px;
          margin-left: 4px; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock .mainImg div {
            height: 308px;
            overflow: hidden;
            position: relative; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock .mainImg div a {
              max-height: 308px; }
              #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock .mainImg div a:after {
                content: "";
                display: block;
                width: 32px;
                height: 32px;
                background: url(../img/contents/searchD_icon.png) no-repeat top left;
                background-size: 32px auto;
                position: absolute;
                right: 10px;
                bottom: 10px; }
              #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock .mainImg div a:hover {
                opacity: 0.8; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock .mainImg div img {
              max-width: 100%;
              max-height: 100%;
              width: auto;
              height: auto; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock .mainImg p {
            font-size: 16px;
            text-align: center; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock {
        width: 390px;
        float: right; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock dl {
          background: url(../img/contents/flow_line.png) repeat-x left bottom;
          background-size: 3px auto;
          font-size: 20px;
          line-height: 34px;
          display: table;
          width: 100%; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock dl:first-of-type {
            background: url(../img/contents/flow_line.png) repeat-x left bottom, url(../img/contents/flow_line.png) repeat-x left top;
            background-size: 3px auto; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock dl dt, #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock dl dd {
            display: table-cell;
            vertical-align: top;
            padding: 16px 0; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock dl dt {
            width: 108px;
            font-weight: 700; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock dl + ul {
          margin-top: 28px; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul {
          font-size: 0; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li {
            display: inline-block;
            width: 60px;
            height: 60px;
            font-size: 20px;
            line-height: 22px;
            font-weight: 700;
            color: #a9905e;
            margin-right: 6px; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li:nth-child(6n) {
              margin-right: 0; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li:nth-child(n+7) {
              margin-top: 6px; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li img {
              width: 100%;
              height: auto; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li > span {
              display: table-cell;
              width: 60px;
              height: 60px;
              vertical-align: middle;
              text-align: center; }
              #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li > span b {
                font-size: 16px; }
              #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li > span span {
                letter-spacing: -2.5px; }
                #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li > span span b {
                  font-size: 18px; }
    #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl {
      display: table;
      width: 100%;
      font-size: 18px;
      line-height: 30px;
      border-right: 1px solid #dfdfdf;
      border-left: 1px solid #dfdfdf; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl:first-of-type {
        border-top: 1px solid #dfdfdf; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl:last-of-type {
        border-bottom: 1px solid #dfdfdf; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl + dl {
        background: url(../img/contents/flow_line.png) repeat-x top left;
        background-size: 3px auto; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl + dl dt {
          background: #f1f9fd url(../img/contents/flow_line.png) repeat-x top left;
          background-size: 3px auto; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl dt, #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl dd {
        display: table-cell;
        vertical-align: top;
        padding: 10px 20px; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl dt {
        font-weight: 700;
        background: #f1f9fd;
        width: 220px; }
    #PageFacility.pageEntry #Main #ContBox01 #ContSubBox03 h3 {
      text-align: left;
      font-size: 30px;
      line-height: 34px;
      margin-bottom: 28px;
      letter-spacing: 1.2px; }
    #PageFacility.pageEntry #Main #ContBox01 #ContSubBox03 div {
      font-size: 20px;
      line-height: 34px; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox03 div p + p {
        margin-top: 20px; }
    #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 {
      background: #f1f9fd;
      border: 1px solid #d6e2ed;
      padding: 38px;
      font-size: 0;
      display: table;
      width: 100%; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .imgBlock {
        display: table-cell;
        vertical-align: top;
        width: 180px;
        height: 180px; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .imgBlock img {
          width: 100%;
          height: auto; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .imgBlock + .textBlock {
          padding-left: 30px; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .textBlock {
        display: table-cell;
        vertical-align: top; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .textBlock h3 {
          font-size: 24px;
          line-height: 28px;
          margin-bottom: 20px;
          text-align: left;
          letter-spacing: 1.2px; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .textBlock div {
          font-size: 18px;
          line-height: 30px; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .textBlock div p {
            font-size: 18px;
            line-height: 30px; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .textBlock div p + p {
              margin-top: 10px; }
    #PageFacility.pageEntry #Main #ContBox01 #ContSubBox05 #Gmap02 {
      width: 100%;
      height: 300px; }
    #PageFacility.pageEntry #Main #ContBox01 #ContSubBox05 div.mapLink {
      background: url(../img/contents/menu_icon02.png) no-repeat center left;
      background-size: 12px auto;
      display: table;
      padding-left: 18px;
      line-height: 24px;
      margin: 10px 0 0 auto; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox05 div.mapLink a {
        font-size: 14px;
        color: #559ad3;
        text-decoration: none; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox05 div.mapLink a:hover {
          opacity: 0.8; }
  /*
  ブログ一覧 共通 pc
  -------------------------------------*/
  #PageTopics #Main {
    width: 710px;
    float: right;
    margin-bottom: 190px; }
    #PageTopics #Main #ContBox01 > h3 {
      line-height: 46px;
      text-align: left;
      letter-spacing: 1.5px;
      margin-bottom: 50px; }
    #PageTopics #Main #ContBox01 ul.blogList {
      font-size: 0;
      padding-bottom: 70px;
      border-bottom: 1px solid #dedede; }
      #PageTopics #Main #ContBox01 ul.blogList > li {
        width: 330px;
        display: inline-block;
        vertical-align: top; }
        #PageTopics #Main #ContBox01 ul.blogList > li:nth-child(2n) {
          margin-left: 50px; }
        #PageTopics #Main #ContBox01 ul.blogList > li:nth-child(n+3) {
          margin-top: 52px; }
        #PageTopics #Main #ContBox01 ul.blogList > li figure {
          width: 100%;
          height: 200px;
          overflow: hidden; }
          #PageTopics #Main #ContBox01 ul.blogList > li figure a {
            display: block;
            width: 100%;
            height: 100%; }
            #PageTopics #Main #ContBox01 ul.blogList > li figure a:hover {
              opacity: 0.8; }
          #PageTopics #Main #ContBox01 ul.blogList > li figure img {
            width: 100%;
            height: auto; }
        #PageTopics #Main #ContBox01 ul.blogList > li dl {
          margin-top: 20px; }
          #PageTopics #Main #ContBox01 ul.blogList > li dl dd {
            font-size: 16px;
            color: #a9905e; }
            #PageTopics #Main #ContBox01 ul.blogList > li dl dd ul {
              display: table;
              width: 100%; }
              #PageTopics #Main #ContBox01 ul.blogList > li dl dd ul li {
                display: table-cell;
                vertical-align: top;
                line-height: 20px;
                text-align: left; }
            #PageTopics #Main #ContBox01 ul.blogList > li dl dd .date {
              border-right: 1px solid #e1e0dd;
              padding-right: 10px;
              letter-spacing: 1px;
              width: 105px; }
            #PageTopics #Main #ContBox01 ul.blogList > li dl dd .category {
              padding-left: 12px;
              font-size: 0; }
              #PageTopics #Main #ContBox01 ul.blogList > li dl dd .category span {
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;
                font-size: 16px; }
          #PageTopics #Main #ContBox01 ul.blogList > li dl dt {
            margin-top: 8px;
            font-size: 20px;
            line-height: 34px; }
            #PageTopics #Main #ContBox01 ul.blogList > li dl dt a {
              color: #444;
              text-decoration: none; }
              #PageTopics #Main #ContBox01 ul.blogList > li dl dt a:hover {
                text-decoration: underline; }
  /*
  ブログ一覧
  -------------------------------------*/
  /*
  ブログカテゴリアーカイブ
  -------------------------------------*/
  /*
  ブログ記事詳細 pc
  -------------------------------------*/
  #PageTopics.pageEntry #Main #ContBox01 > h3 {
    color: #444;
    margin-bottom: 12px; }
    #PageTopics.pageEntry #Main #ContBox01 > h3 + ul {
      display: table;
      width: 100%; }
      #PageTopics.pageEntry #Main #ContBox01 > h3 + ul li {
        font-size: 16px;
        color: #a9905e;
        display: table-cell;
        vertical-align: top;
        line-height: 20px;
        text-align: left; }
        #PageTopics.pageEntry #Main #ContBox01 > h3 + ul li.date {
          border-right: 1px solid #e1e0dd;
          padding-right: 10px;
          letter-spacing: 1px;
          width: 105px; }
        #PageTopics.pageEntry #Main #ContBox01 > h3 + ul li.category {
          padding-left: 12px;
          font-size: 0; }
          #PageTopics.pageEntry #Main #ContBox01 > h3 + ul li.category span {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            font-size: 16px; }
  #PageTopics.pageEntry #Main #ContBox01 .entryBox {
    margin-top: 38px;
    padding-bottom: 70px;
    border-bottom: 1px solid #dedede; }
    #PageTopics.pageEntry #Main #ContBox01 .entryBox img {
      margin-bottom: 20px; }
    #PageTopics.pageEntry #Main #ContBox01 .entryBox p + p {
      margin-top: 20px; }
  /*
  運営会社 pc
  -------------------------------------*/
  #PageAbout.pageIndex #MainTitle {
    padding-bottom: 90px; }
  #PageAbout.pageIndex #Main {
    margin-bottom: 90px; }
    #PageAbout.pageIndex #Main #ContBox01 {
      padding-top: 20px; }
      #PageAbout.pageIndex #Main #ContBox01 figure img {
        width: 100%;
        height: auto; }
      #PageAbout.pageIndex #Main #ContBox01 .imgTxt p {
        float: right;
        width: 610px;
        margin-top: -6px; }
      #PageAbout.pageIndex #Main #ContBox01 .imgTxt figure {
        width: 360px;
        float: left; }
      #PageAbout.pageIndex #Main #ContBox01 .imgTxt + p {
        margin: 17px 0 27px; }
      #PageAbout.pageIndex #Main #ContBox01 .imgTxt02 div {
        width: 670px;
        float: left;
        margin-top: -7px; }
        #PageAbout.pageIndex #Main #ContBox01 .imgTxt02 div p + p {
          margin-top: 20px; }
      #PageAbout.pageIndex #Main #ContBox01 .imgTxt02 figure {
        width: 300px;
        float: right; }
    #PageAbout.pageIndex #Main #ContBox02 {
      margin-top: 60px; }
      #PageAbout.pageIndex #Main #ContBox02.introductionBox {
        padding: 50px 0; }
        #PageAbout.pageIndex #Main #ContBox02.introductionBox .innerBasic:before {
          top: -64px;
          left: 120px; }
        #PageAbout.pageIndex #Main #ContBox02.introductionBox .txt {
          display: block;
          padding-left: 302px;
          background: url(../img/contents/unei_img03.png) no-repeat top left;
          background-size: 270px auto;
          min-height: 270px; }
          #PageAbout.pageIndex #Main #ContBox02.introductionBox .txt dl {
            padding-top: 20px; }
          #PageAbout.pageIndex #Main #ContBox02.introductionBox .txt p {
            text-align: left;
            margin-top: 13px; }
    #PageAbout.pageIndex #Main #ContBox03 {
      margin-top: 80px;
      padding-top: 20px;
      overflow: hidden; }
      #PageAbout.pageIndex #Main #ContBox03 > div:first-of-type {
        width: 470px;
        float: left; }
        #PageAbout.pageIndex #Main #ContBox03 > div:first-of-type #AboutGmap {
          width: 100%;
          height: 470px; }
          #PageAbout.pageIndex #Main #ContBox03 > div:first-of-type #AboutGmap + div {
            background: url(../img/contents/menu_icon02.png) no-repeat center left;
            background-size: 12px auto;
            display: table;
            padding-left: 17px;
            margin: 10px 0 0 auto; }
            #PageAbout.pageIndex #Main #ContBox03 > div:first-of-type #AboutGmap + div a {
              font-size: 14px;
              line-height: 24px;
              color: #559ad3;
              text-decoration: none; }
              #PageAbout.pageIndex #Main #ContBox03 > div:first-of-type #AboutGmap + div a:hover {
                opacity: 0.8; }
      #PageAbout.pageIndex #Main #ContBox03 > div + div {
        width: 470px;
        float: right; }
        #PageAbout.pageIndex #Main #ContBox03 > div + div dl {
          border-bottom: 1px solid #ebebeb;
          font-size: 0;
          padding: 0 0 19px;
          display: table;
          width: 100%; }
          #PageAbout.pageIndex #Main #ContBox03 > div + div dl + dl {
            padding-top: 20px; }
          #PageAbout.pageIndex #Main #ContBox03 > div + div dl dt, #PageAbout.pageIndex #Main #ContBox03 > div + div dl dd {
            display: table-cell;
            vertical-align: top;
            font-size: 20px;
            line-height: 34px; }
          #PageAbout.pageIndex #Main #ContBox03 > div + div dl dt {
            font-weight: 700;
            width: 120px; }
        #PageAbout.pageIndex #Main #ContBox03 > div + div p {
          font-size: 18px;
          line-height: 30px;
          color: #777777;
          margin-top: 24px; }
  /*
  お問い合わせ pc
  -------------------------------------*/
  #PageContact.pageIndex #Main,
  #PageContact.pageConfirm #Main {
    margin-bottom: 167px; }
    #PageContact.pageIndex #Main #ContBox01 .tel,
    #PageContact.pageConfirm #Main #ContBox01 .tel {
      color: #444;
      text-decoration: none;
      cursor: default; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 p,
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 p {
      text-align: center; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 p + div,
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 p + div {
      text-align: center;
      line-height: 34px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 p + div a,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 p + div a {
        color: #559ad3;
        font-size: 16px; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form,
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form {
      margin-top: 50px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl {
        width: 100%;
        display: table;
        border-bottom: 1px solid #ebebeb;
        padding: 22px 0 21px;
        color: #444; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl:first-of-type,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl:first-of-type {
          border-top: 1px solid #ebebeb;
          padding: 30px 0 27px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl:first-of-type dt, #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl:first-of-type dd:first-of-type,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl:first-of-type dt,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl:first-of-type dd:first-of-type {
            padding-top: 0; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dt, #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dt,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd {
          display: table-cell;
          vertical-align: top;
          font-size: 20px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dt,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dt {
          width: 300px;
          font-weight: 700;
          padding-top: 8px;
          line-height: 34px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type {
          width: 60px;
          padding-top: 8px;
          font-size: 14px;
          font-weight: 700;
          line-height: 34px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span {
            color: #858585; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span.required,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span.required {
              color: #d55c5c; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child {
          width: 640px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"],
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"],
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"],
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"],
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"],
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"],
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea {
            width: 240px;
            height: 50px;
            border: 1px solid #c0bfbd;
            background: #f8f7f6;
            padding-left: 14px; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"]:focus,
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"]:focus,
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"]:focus,
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea:focus,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"]:focus,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"]:focus,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"]:focus,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea:focus {
              box-shadow: 1px 1px 1px #0979db, -1px -1px 1px #0979db; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input::placeholder,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input::placeholder {
            color: #adadad; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea::placeholder,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea::placeholder {
            color: #adadad; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text4, #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text5,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text4,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text5 {
            width: 430px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text6, #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text7,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text6,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text7 {
            width: 218px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text8,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text8 {
            width: 100%; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text9,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text9 {
            width: 490px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea {
            width: 100%;
            height: 250px;
            padding: 10px 20px 10px 14px;
            resize: none;
            line-height: 34px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span {
            width: 34px;
            height: 34px;
            margin-right: 5px;
            background: transparent url(../img/contents/radio01.png) no-repeat scroll left center;
            background-size: 34px 34px; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span.checked,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span.checked {
              background: transparent url(../img/contents/radio02.png) no-repeat scroll left center;
              background-size: 34px 34px; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span input,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span input {
              width: 34px;
              height: 34px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child label,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child label {
            display: inline-block;
            margin-right: 59px;
            line-height: 34px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul {
        position: relative;
        width: 100%;
        margin: 60px 0 0; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul li.reset,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul li.reset {
          position: absolute;
          top: 0;
          left: 0;
          line-height: 72px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"],
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"] {
            opacity: 1;
            color: #559ad3;
            font-size: 16px;
            text-decoration: underline; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"]:hover,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"]:hover {
              text-decoration: none; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul li #image-btn,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul li #image-btn {
          display: block;
          width: 368px;
          height: 72px;
          margin: 0px auto;
          border-radius: 7px;
          opacity: 1;
          color: #fff;
          font-size: 28px;
          font-weight: 700;
          line-height: 72px;
          letter-spacing: 0.5px;
          background: #6ea7d8; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul li #image-btn:hover,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul li #image-btn:hover {
            opacity: 0.8; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox02,
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 {
      margin-top: 180px;
      padding-top: 119px;
      border-top: 1px solid #eeeeee; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 h3,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 h3 {
        margin-bottom: 49px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 h4,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 h4 {
        font-size: 28px;
        line-height: 34px;
        font-weight: 700;
        margin: 47px 0 22px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 p + p,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 p + p {
        margin-top: 19px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 ul li,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 ul li {
        font-size: 20px;
        line-height: 34px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 dl,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 dl {
        margin-top: 25px;
        font-size: 20px;
        line-height: 34px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 dl dt,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 dl dt {
          font-weight: 700; }
  /*
  お問い合わせ内容確認 pc
  -------------------------------------*/
  #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 h3 {
    margin-bottom: 47px; }
  #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form#form1 dl {
    padding: 30px 0 29px; }
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form#form1 dl dt, #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form#form1 dl dd {
      padding-top: 0; }
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form#form1 dl dd {
      line-height: 34px; }
  /*
  お問い合わせ送信完了 pc
  -------------------------------------*/
  #PageContact.pageThanks #Main #ContBox01 {
    margin-bottom: 190px; }
    #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 p {
      text-align: center; }
      #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 p + div {
        width: 368px;
        height: 72px;
        line-height: 72px;
        text-align: center;
        margin: 30px auto 0; }
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 p + div a {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 7px;
          border: 1px solid #d3d3d3;
          font-size: 28px;
          font-weight: 700;
          color: #949494;
          background: #e3e3e3;
          text-decoration: none; }
          #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 p + div a:hover {
            opacity: 0.8; } }

@media print, screen and (max-width: 767px) {
  /*
  404
  -------------------------------------*/
  /*
  トップページ 施設 共通  sp
  -------------------------------------*/
  #PageIndex.pageIndex #Main #ContBox02,
  #PageFacility.pageIndex #Main #ContBox01,
  #PageFacility.pageCategory #Main #ContBox01 {
    padding: 0 15px; }
    #PageIndex.pageIndex #Main #ContBox02 .searchTitle,
    #PageFacility.pageIndex #Main #ContBox01 .searchTitle,
    #PageFacility.pageCategory #Main #ContBox01 .searchTitle {
      font-size: 16px;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 8px;
      letter-spacing: 1px;
      color: #444;
      text-align: left; }
      #PageIndex.pageIndex #Main #ContBox02 .searchTitle span,
      #PageFacility.pageIndex #Main #ContBox01 .searchTitle span,
      #PageFacility.pageCategory #Main #ContBox01 .searchTitle span {
        color: #4391d3; }
    #PageIndex.pageIndex #Main #ContBox02 div .search,
    #PageFacility.pageIndex #Main #ContBox01 div .search,
    #PageFacility.pageCategory #Main #ContBox01 div .search {
      width: 100%;
      border: 3px solid #ededed;
      border-radius: 6px; }
      #PageIndex.pageIndex #Main #ContBox02 div .search ul li,
      #PageFacility.pageIndex #Main #ContBox01 div .search ul li,
      #PageFacility.pageCategory #Main #ContBox01 div .search ul li {
        width: 100%;
        padding: 11px 20px 13px 45px;
        background: url(../img/menu_arrow02.png) no-repeat top 15px left 17px;
        background-size: 19px auto; }
        #PageIndex.pageIndex #Main #ContBox02 div .search ul li + li,
        #PageFacility.pageIndex #Main #ContBox01 div .search ul li + li,
        #PageFacility.pageCategory #Main #ContBox01 div .search ul li + li {
          border-top: 1px solid #ebebeb; }
        #PageIndex.pageIndex #Main #ContBox02 div .search ul li a,
        #PageFacility.pageIndex #Main #ContBox01 div .search ul li a,
        #PageFacility.pageCategory #Main #ContBox01 div .search ul li a {
          font-size: 18px;
          line-height: 29px;
          color: #444;
          text-decoration: none; }
        #PageIndex.pageIndex #Main #ContBox02 div .search ul li.current a, #PageIndex.pageIndex #Main #ContBox02 div .search ul li.current-cat a,
        #PageFacility.pageIndex #Main #ContBox01 div .search ul li.current a,
        #PageFacility.pageIndex #Main #ContBox01 div .search ul li.current-cat a,
        #PageFacility.pageCategory #Main #ContBox01 div .search ul li.current a,
        #PageFacility.pageCategory #Main #ContBox01 div .search ul li.current-cat a {
          color: #a3a3a3; }
    #PageIndex.pageIndex #Main #ContBox02 #ContSubBox01 .searchTitle,
    #PageFacility.pageIndex #Main #ContBox01 #ContSubBox01 .searchTitle,
    #PageFacility.pageCategory #Main #ContBox01 #ContSubBox01 .searchTitle {
      background: url(../img/index_icon01.png) no-repeat center left 2px;
      background-size: 26px auto;
      padding-left: 42px; }
    #PageIndex.pageIndex #Main #ContBox02 #ContSubBox01 .link02,
    #PageFacility.pageIndex #Main #ContBox01 #ContSubBox01 .link02,
    #PageFacility.pageCategory #Main #ContBox01 #ContSubBox01 .link02 {
      margin: 18px 0 28px; }
      #PageIndex.pageIndex #Main #ContBox02 #ContSubBox01 .link02 a,
      #PageFacility.pageIndex #Main #ContBox01 #ContSubBox01 .link02 a,
      #PageFacility.pageCategory #Main #ContBox01 #ContSubBox01 .link02 a {
        font-size: 14px; }
    #PageIndex.pageIndex #Main #ContBox02 #ContSubBox02 .searchTitle,
    #PageFacility.pageIndex #Main #ContBox01 #ContSubBox02 .searchTitle,
    #PageFacility.pageCategory #Main #ContBox01 #ContSubBox02 .searchTitle {
      background: url(../img/index_icon02.png) no-repeat center left 2px;
      background-size: 26px auto;
      padding-left: 40px; }
    #PageIndex.pageIndex #Main #ContBox02 #ContSubBox03,
    #PageFacility.pageIndex #Main #ContBox01 #ContSubBox03,
    #PageFacility.pageCategory #Main #ContBox01 #ContSubBox03 {
      margin-top: 28px; }
      #PageIndex.pageIndex #Main #ContBox02 #ContSubBox03 .searchTitle,
      #PageFacility.pageIndex #Main #ContBox01 #ContSubBox03 .searchTitle,
      #PageFacility.pageCategory #Main #ContBox01 #ContSubBox03 .searchTitle {
        background: url(../img/index_icon03.png) no-repeat center left;
        background-size: 33px auto;
        padding-left: 40px; }
      #PageIndex.pageIndex #Main #ContBox02 #ContSubBox03 #Gmap01,
      #PageFacility.pageIndex #Main #ContBox01 #ContSubBox03 #Gmap01,
      #PageFacility.pageCategory #Main #ContBox01 #ContSubBox03 #Gmap01 {
        width: 100%;
        height: 350px; }
      #PageIndex.pageIndex #Main #ContBox02 #ContSubBox03 .mapName a,
      #PageFacility.pageIndex #Main #ContBox01 #ContSubBox03 .mapName a,
      #PageFacility.pageCategory #Main #ContBox01 #ContSubBox03 .mapName a {
        text-decoration: underline;
        font-size: 14px; }
  /*
  トップページ sp
  -------------------------------------*/
  #PageIndex.pageIndex #Main {
    margin-bottom: 70px; }
    #PageIndex.pageIndex #Main #ContBox01 div.img {
      width: 135px;
      background: url(../img/index_iimg01.png) no-repeat top left;
      background-size: 135px auto;
      padding-top: 137px;
      text-align: center; }
    #PageIndex.pageIndex #Main #ContBox01 div.txt {
      padding-left: 15px;
      text-align: right; }
      #PageIndex.pageIndex #Main #ContBox01 div.txt dl {
        text-align: left; }
        #PageIndex.pageIndex #Main #ContBox01 div.txt dl dd {
          text-align: justify; }
      #PageIndex.pageIndex #Main #ContBox01 div.txt .link {
        display: inline-block;
        margin-top: 22px; }
    #PageIndex.pageIndex #Main #ContBox02 {
      margin-top: 35px; }
    #PageIndex.pageIndex #Main #ContBox03 {
      border-top: 1px solid #eee;
      margin-top: 50px;
      padding: 43px 15px 0; }
      #PageIndex.pageIndex #Main #ContBox03 h2 {
        color: #444;
        font-size: 26px;
        font-weight: 700;
        line-height: 40px;
        background: url(../img/index_icon04.png) no-repeat center left;
        background-size: 32px auto;
        padding-left: 44px; }
      #PageIndex.pageIndex #Main #ContBox03 .title {
        position: relative; }
        #PageIndex.pageIndex #Main #ContBox03 .title .link {
          position: absolute;
          right: 0;
          top: 0;
          line-height: 40px;
          height: 40px; }
      #PageIndex.pageIndex #Main #ContBox03 ul.blogList {
        margin-top: 25px; }
        #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li {
          display: table;
          width: 100%; }
          #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li + li {
            margin-top: 25px; }
          #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li figure {
            width: 110px;
            height: 68px;
            overflow: hidden;
            display: table-cell;
            vertical-align: top; }
            #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li figure a {
              display: block;
              width: 110px;
              height: 68px; }
            #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li figure img {
              width: 100%;
              height: auto; }
          #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl {
            display: table-cell;
            vertical-align: top;
            padding-left: 15px; }
            #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dd {
              font-size: 14px;
              color: #a9905e; }
              #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dd ul {
                display: table;
                width: 100%; }
                #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dd ul li {
                  display: table-cell;
                  vertical-align: top;
                  line-height: 20px;
                  text-align: left; }
              #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dd .date {
                border-right: 1px solid #e1e0dd;
                padding-right: 8px;
                letter-spacing: 0.8px;
                width: 95px; }
              #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dd .category {
                padding-left: 9px; }
            #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dt {
              margin-top: 8px;
              font-size: 16px;
              line-height: 25px; }
              #PageIndex.pageIndex #Main #ContBox03 ul.blogList > li dl dt a {
                color: #444;
                text-decoration: none; }
  /*
  ご入居までの流れ sp
  -------------------------------------*/
  #PageInfo.pageIndex #Main #ContBox01 {
    margin-bottom: 85px; }
    #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 {
      padding: 0 15px; }
      #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol {
        list-style: none; }
        #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li {
          background: url(../img/contents/flow_line.png) repeat-x top left;
          background-size: 3px auto; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div {
            padding: 48px 0 50px 28px;
            position: relative; }
            #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div:before {
              content: "";
              display: block;
              width: 2px;
              height: 100%;
              background: #9cc4e7;
              position: absolute;
              left: 7px;
              top: 0;
              z-index: 5; }
            #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div > dl > dt {
              font-size: 35px;
              font-weight: bold;
              color: #4391d3;
              line-height: 42px;
              margin-bottom: 18px;
              position: relative; }
              #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div > dl > dt:before {
                content: "";
                display: block;
                width: 16px;
                height: 16px;
                background: #9cc4e7;
                border-radius: 50%;
                position: absolute;
                top: 12px;
                left: -28px;
                z-index: 10; }
            #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div > dl > dd {
              font-size: 18px;
              line-height: 29px;
              padding-left: 2px; }
            #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo {
              margin-top: 18px; }
              #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.tel {
                max-width: 295px; }
                #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.tel dl {
                  display: table;
                  margin: 0 auto; }
                  #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.tel dl dt {
                    white-space: nowrap;
                    padding-left: 0;
                    background: none; }
                    #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.tel dl dt:before {
                      content: "";
                      display: inline-block;
                      width: 40px;
                      height: 35px;
                      vertical-align: top;
                      background: url(../img/tel_icon.png) no-repeat center left;
                      background-size: 40px auto;
                      position: relative;
                      margin-right: 6px; }
                  #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.tel dl dd {
                    text-align: center; }
              #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.contact {
                text-align: center;
                margin: 19px 0 0; }
                #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li > div ul.contactInfo li.contact a {
                  border-radius: 3px; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:first-child > div {
            position: relative; }
            #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:first-child > div:after {
              content: "";
              display: block;
              width: 2px;
              height: 70px;
              background: #fff;
              position: absolute;
              left: 7px;
              top: 0;
              z-index: 8; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:nth-child(n+2) > div > dl {
            padding-bottom: 265px; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:nth-child(2) > div > dl {
            background: url(../img/contents/flow_img01.png) no-repeat bottom center;
            background-size: 239px auto; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:nth-child(3) > div > dl {
            background: url(../img/contents/flow_img02.png) no-repeat bottom center;
            background-size: 239px auto; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:nth-child(4) > div > dl {
            background: url(../img/contents/flow_img03.png) no-repeat bottom center;
            background-size: 239px auto; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:nth-child(5) > div > dl {
            background: url(../img/contents/flow_img04.png) no-repeat bottom center;
            background-size: 239px auto; }
          #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:last-child {
            background: url(../img/contents/flow_line.png) repeat-x top left, url(../img/contents/flow_line.png) repeat-x bottom left;
            background-size: 3px auto; }
            #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:last-child > div > dl {
              padding-bottom: 0; }
            #PageInfo.pageIndex #Main #ContBox01 #ContSubBox01 ol > li:last-child > div:before {
              height: 70px; }
  /*
  施設 共通 pc
  -------------------------------------*/
  #PageFacility.pageIndex #Main,
  #PageFacility.pageCategory #Main {
    margin-bottom: 160px; }
    #PageFacility.pageIndex #Main #ContBox02,
    #PageFacility.pageCategory #Main #ContBox02 {
      margin-top: 78px;
      padding: 0 15px; }
      #PageFacility.pageIndex #Main #ContBox02 h3,
      #PageFacility.pageCategory #Main #ContBox02 h3 {
        font-size: 28px;
        line-height: 1;
        margin-bottom: 59px; }
      #PageFacility.pageIndex #Main #ContBox02 .newList li,
      #PageFacility.pageCategory #Main #ContBox02 .newList li {
        vertical-align: top; }
        #PageFacility.pageIndex #Main #ContBox02 .newList li + li,
        #PageFacility.pageCategory #Main #ContBox02 .newList li + li {
          margin-top: 80px; }
        #PageFacility.pageIndex #Main #ContBox02 .newList li figure,
        #PageFacility.pageCategory #Main #ContBox02 .newList li figure {
          overflow: hidden; }
          #PageFacility.pageIndex #Main #ContBox02 .newList li figure a,
          #PageFacility.pageCategory #Main #ContBox02 .newList li figure a {
            display: block;
            width: 100%;
            height: 100%; }
          #PageFacility.pageIndex #Main #ContBox02 .newList li figure img,
          #PageFacility.pageCategory #Main #ContBox02 .newList li figure img {
            width: 100%;
            height: auto; }
        #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl,
        #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl {
          margin-top: 18px;
          color: #444; }
          #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.type,
          #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.type {
            font-size: 16px;
            line-height: 19px;
            font-weight: 700;
            margin-bottom: 4px; }
          #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info,
          #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info {
            margin-top: 14px; }
            #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl,
            #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl {
              display: table;
              width: 100%;
              font-size: 16px;
              line-height: 27px;
              border-right: 1px solid #dfdfdf;
              border-left: 1px solid #dfdfdf; }
              #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl:first-of-type,
              #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl:first-of-type {
                border-top: 1px solid #dfdfdf; }
              #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl:last-of-type,
              #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl:last-of-type {
                border-bottom: 1px solid #dfdfdf; }
              #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl + dl,
              #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl + dl {
                background: url(../img/contents/flow_line.png) repeat-x top left;
                background-size: 3px auto; }
                #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl + dl dt,
                #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl + dl dt {
                  background: #f1f9fd url(../img/contents/flow_line.png) repeat-x top left;
                  background-size: 3px auto; }
              #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl dt, #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl dd,
              #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl dt,
              #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl dd {
                display: table-cell;
                vertical-align: top;
                padding: 10px; }
              #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dd.info dl dt,
              #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dd.info dl dt {
                font-weight: 700;
                background: #f1f9fd;
                width: 108px; }
          #PageFacility.pageIndex #Main #ContBox02 .newList li > div > dl > dt,
          #PageFacility.pageCategory #Main #ContBox02 .newList li > div > dl > dt {
            font-size: 26px;
            line-height: 35px;
            font-weight: 700;
            letter-spacing: 1px; }
        #PageFacility.pageIndex #Main #ContBox02 .newList li > div div,
        #PageFacility.pageCategory #Main #ContBox02 .newList li > div div {
          width: 100%;
          height: 45px;
          line-height: 45px;
          margin-top: 20px; }
          #PageFacility.pageIndex #Main #ContBox02 .newList li > div div a,
          #PageFacility.pageCategory #Main #ContBox02 .newList li > div div a {
            display: block;
            width: 100%;
            height: 100%;
            border: 1px solid #6ea7d8;
            border-radius: 3px;
            background: url(../img/menu_arrow01.png) no-repeat center right 18px;
            background-size: 11px auto;
            color: #559ad3;
            font-size: 14px;
            text-align: center;
            text-decoration: none; }
  /*
  施設
  -------------------------------------*/
  /*
  施設カテゴリアーカイブ（子カテゴリ） sp
  -------------------------------------*/
  #PageFacility.pageCategory #Main #ContBox01 h3 {
    line-height: 42px;
    margin-bottom: 40px; }
  /*
  施設詳細 sp
  -------------------------------------*/
  #PageFacility.pageEntry #Main {
    margin-top: 47px;
    margin-bottom: 75px; }
    #PageFacility.pageEntry #Main #ContBox01 {
      padding: 0 15px; }
      #PageFacility.pageEntry #Main #ContBox01 h2 {
        font-size: 35px;
        font-weight: 700;
        line-height: 42px;
        color: #444;
        border-bottom: 3px solid #ede6d9;
        padding-bottom: 13px;
        margin-top: 6px;
        letter-spacing: 1.2px; }
      #PageFacility.pageEntry #Main #ContBox01 span.type {
        font-size: 22px;
        font-weight: 700;
        line-height: 29px;
        color: #444;
        display: block;
        letter-spacing: 1px; }
      #PageFacility.pageEntry #Main #ContBox01 > ul {
        margin: 5px 0 34px;
        font-size: 0; }
        #PageFacility.pageEntry #Main #ContBox01 > ul li {
          display: inline-block;
          border: 1px solid #dce5ec;
          border-radius: 3px;
          background: #e9f4fe;
          color: #4391d3;
          font-size: 12px;
          font-weight: 700;
          padding: 0 4px;
          height: 24px;
          line-height: 24px;
          margin-right: 8px;
          letter-spacing: 0.5px;
          margin-top: 10px; }
      #PageFacility.pageEntry #Main #ContBox01 .contSubBox {
        margin-top: 50px; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 {
        margin-top: 24px; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock {
          display: table;
          width: 100%;
          table-layout: fixed; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock img {
            width: auto;
            height: 100%; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock ul {
            display: table-cell;
            vertical-align: top;
            width: 37px; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock ul li {
              width: 100%;
              height: 37px;
              overflow: hidden;
              cursor: pointer; }
              #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock ul li + li {
                margin-top: 1px; }
              #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock ul li img {
                width: auto;
                height: 100%; }
              #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock ul li a {
                display: block;
                width: 100%;
                height: 100%; }
              #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock ul li.current {
                opacity: 0.2; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock .mainImg {
            display: table-cell;
            vertical-align: top;
            padding-left: 4px; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock .mainImg > div {
              height: 189px;
              overflow: hidden;
              position: relative; }
              #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock .mainImg > div a {
                max-height: 189px; }
                #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock .mainImg > div a:after {
                  content: "";
                  display: block;
                  width: 32px;
                  height: 32px;
                  background: url(../img/contents/searchD_icon.png) no-repeat top left;
                  background-size: 32px auto;
                  position: absolute;
                  right: 5px;
                  bottom: 5px; }
              #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock .mainImg > div img {
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .imgBlock .mainImg p {
              font-size: 14px;
              text-align: center; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock {
          margin-top: 23px; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock dl {
            background: url(../img/contents/flow_line.png) repeat-x left bottom;
            background-size: 3px auto;
            font-size: 18px;
            line-height: 29px;
            display: table;
            width: 100%; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock dl:first-of-type {
              background: url(../img/contents/flow_line.png) repeat-x left bottom, url(../img/contents/flow_line.png) repeat-x left top;
              background-size: 3px auto; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock dl dt, #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock dl dd {
              display: table-cell;
              vertical-align: top;
              padding: 16px 0; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock dl dt {
              width: 108px;
              font-weight: 700; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul {
            font-size: 0;
            margin-top: 22px; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li {
              display: inline-block;
              width: 60px;
              height: 60px;
              font-size: 20px;
              line-height: 22px;
              font-weight: 700;
              color: #a9905e;
              margin-right: 6px;
              margin-top: 6px; }
              #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li img {
                width: 100%;
                height: auto; }
              #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li > span {
                display: table-cell;
                width: 60px;
                height: 60px;
                vertical-align: middle;
                text-align: center; }
                #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li > span b {
                  font-size: 16px; }
                #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li > span span {
                  letter-spacing: -2.5px; }
                  #PageFacility.pageEntry #Main #ContBox01 #ContSubBox01 .infoBlock ul li > span span b {
                    font-size: 18px; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl {
        display: table;
        width: 100%;
        font-size: 16px;
        line-height: 27px;
        border-right: 1px solid #dfdfdf;
        border-left: 1px solid #dfdfdf; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl:first-of-type {
          border-top: 1px solid #dfdfdf; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl:last-of-type {
          border-bottom: 1px solid #dfdfdf; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl + dl {
          background: url(../img/contents/flow_line.png) repeat-x top left;
          background-size: 3px auto; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl + dl dt {
            background: #f1f9fd url(../img/contents/flow_line.png) repeat-x top left;
            background-size: 3px auto; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl dt, #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl dd {
          display: table-cell;
          vertical-align: top;
          padding: 10px; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox02 dl dt {
          font-weight: 700;
          background: #f1f9fd;
          width: 110px; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox03 h3 {
        text-align: left;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 13px;
        letter-spacing: 1.2px; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox03 div {
        font-size: 18px;
        line-height: 29px; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox03 div p + p {
          margin-top: 10px; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 {
        background: #f1f9fd;
        border: 1px solid #d6e2ed;
        padding: 30px 20px;
        font-size: 0; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .imgBlock {
          margin: 0 auto 17px;
          width: 180px; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .imgBlock img {
            width: 100%;
            height: auto; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .textBlock h3 {
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 10px;
          text-align: left;
          letter-spacing: 1px; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .textBlock div {
          font-size: 16px;
          line-height: 27px; }
          #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .textBlock div p {
            font-size: 16px;
            line-height: 27px; }
            #PageFacility.pageEntry #Main #ContBox01 #ContSubBox04 .textBlock div p + p {
              margin-top: 6px; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox05 #Gmap02 {
        width: 100%;
        height: 300px; }
      #PageFacility.pageEntry #Main #ContBox01 #ContSubBox05 div.mapLink {
        background: url(../img/contents/menu_icon02.png) no-repeat center left;
        background-size: 12px auto;
        display: table;
        padding-left: 18px;
        line-height: 24px;
        margin: 10px 0 0 auto; }
        #PageFacility.pageEntry #Main #ContBox01 #ContSubBox05 div.mapLink a {
          font-size: 12px;
          color: #559ad3;
          text-decoration: none; }
  /*
  ブログ一覧 共通 sp
  -------------------------------------*/
  #PageTopics #Main #ContBox01 {
    padding: 0 15px; }
    #PageTopics #Main #ContBox01 > h3 {
      line-height: 42px;
      text-align: left;
      margin-bottom: 40px;
      letter-spacing: 1.5px; }
    #PageTopics #Main #ContBox01 ul.blogList {
      font-size: 0;
      padding-bottom: 80px;
      border-bottom: 1px solid #dedede; }
      #PageTopics #Main #ContBox01 ul.blogList > li + li {
        margin-top: 44px; }
      #PageTopics #Main #ContBox01 ul.blogList > li figure {
        width: 100%; }
        #PageTopics #Main #ContBox01 ul.blogList > li figure a {
          display: block;
          width: 100%;
          height: 100%; }
        #PageTopics #Main #ContBox01 ul.blogList > li figure img {
          width: 100%;
          height: auto; }
      #PageTopics #Main #ContBox01 ul.blogList > li dl {
        margin-top: 20px; }
        #PageTopics #Main #ContBox01 ul.blogList > li dl dd {
          font-size: 14px;
          color: #a9905e; }
          #PageTopics #Main #ContBox01 ul.blogList > li dl dd ul {
            display: table;
            width: 100%; }
            #PageTopics #Main #ContBox01 ul.blogList > li dl dd ul li {
              display: table-cell;
              vertical-align: top;
              line-height: 18px;
              text-align: left; }
          #PageTopics #Main #ContBox01 ul.blogList > li dl dd .date {
            border-right: 1px solid #e1e0dd;
            padding-right: 10px;
            letter-spacing: 1px;
            width: 95px; }
          #PageTopics #Main #ContBox01 ul.blogList > li dl dd .category {
            padding-left: 12px;
            font-size: 0; }
            #PageTopics #Main #ContBox01 ul.blogList > li dl dd .category span {
              display: inline-block;
              vertical-align: top;
              margin-right: 10px;
              font-size: 14px; }
        #PageTopics #Main #ContBox01 ul.blogList > li dl dt {
          margin-top: 8px;
          font-size: 18px;
          line-height: 29px; }
          #PageTopics #Main #ContBox01 ul.blogList > li dl dt a {
            color: #444;
            text-decoration: none; }
  /*
  ブログ一覧
  -------------------------------------*/
  /*
  ブログカテゴリアーカイブ
  -------------------------------------*/
  /*
  ブログ記事詳細 sp
  -------------------------------------*/
  #PageTopics.pageEntry #Main #ContBox01 > h3 {
    color: #444;
    margin-bottom: 12px; }
    #PageTopics.pageEntry #Main #ContBox01 > h3 + ul {
      display: table;
      width: 100%; }
      #PageTopics.pageEntry #Main #ContBox01 > h3 + ul li {
        font-size: 14px;
        color: #a9905e;
        display: table-cell;
        vertical-align: top;
        line-height: 18px;
        text-align: left; }
        #PageTopics.pageEntry #Main #ContBox01 > h3 + ul li.date {
          border-right: 1px solid #e1e0dd;
          padding-right: 10px;
          letter-spacing: 1px;
          width: 95px; }
        #PageTopics.pageEntry #Main #ContBox01 > h3 + ul li.category {
          padding-left: 12px;
          font-size: 0; }
          #PageTopics.pageEntry #Main #ContBox01 > h3 + ul li.category span {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            font-size: 14px; }
  #PageTopics.pageEntry #Main #ContBox01 .entryBox {
    margin-top: 30px;
    padding-bottom: 45px;
    border-bottom: 1px solid #dedede; }
    #PageTopics.pageEntry #Main #ContBox01 .entryBox img {
      margin-bottom: 20px;
      width: 100%;
      height: auto; }
    #PageTopics.pageEntry #Main #ContBox01 .entryBox p + p {
      margin-top: 10px; }
  /*
  運営会社 sp
  -------------------------------------*/
  #PageAbout.pageIndex #MainTitle {
    padding-bottom: 42px; }
  #PageAbout.pageIndex #Main {
    margin-bottom: 65px; }
    #PageAbout.pageIndex #Main #ContBox01 {
      padding: 20px 15px 0; }
      #PageAbout.pageIndex #Main #ContBox01 figure img {
        width: 100%;
        height: auto; }
      #PageAbout.pageIndex #Main #ContBox01 .imgTxt figure {
        margin: 25px 0; }
      #PageAbout.pageIndex #Main #ContBox01 .imgTxt02 p {
        margin-top: 10px; }
      #PageAbout.pageIndex #Main #ContBox01 .imgTxt02 figure {
        width: 225px;
        margin: 22px auto 0; }
    #PageAbout.pageIndex #Main #ContBox02 {
      margin-top: 70px; }
      #PageAbout.pageIndex #Main #ContBox02.introductionBox {
        padding: 60px 15px; }
        #PageAbout.pageIndex #Main #ContBox02.introductionBox .innerBasic {
          display: block; }
          #PageAbout.pageIndex #Main #ContBox02.introductionBox .innerBasic:before {
            top: -74px; }
        #PageAbout.pageIndex #Main #ContBox02.introductionBox .txt {
          display: block;
          padding-bottom: 300px;
          background: url(../img/contents/unei_img03.png) no-repeat bottom center;
          background-size: 271px auto; }
          #PageAbout.pageIndex #Main #ContBox02.introductionBox .txt p {
            margin-top: 14px;
            text-align: left; }
            #PageAbout.pageIndex #Main #ContBox02.introductionBox .txt p span {
              letter-spacing: 0.5px; }
    #PageAbout.pageIndex #Main #ContBox03 {
      margin-top: 49px;
      padding-top: 20px;
      padding: 0 15px; }
      #PageAbout.pageIndex #Main #ContBox03 > div:first-of-type #AboutGmap {
        width: 100%;
        height: 350px; }
        #PageAbout.pageIndex #Main #ContBox03 > div:first-of-type #AboutGmap + div {
          background: url(../img/contents/menu_icon02.png) no-repeat center left;
          background-size: 12px auto;
          display: table;
          padding-left: 17px;
          margin: 10px 0 0 auto; }
          #PageAbout.pageIndex #Main #ContBox03 > div:first-of-type #AboutGmap + div a {
            font-size: 12px;
            line-height: 20px;
            color: #559ad3;
            text-decoration: none; }
      #PageAbout.pageIndex #Main #ContBox03 > div + div {
        margin-top: 38px; }
        #PageAbout.pageIndex #Main #ContBox03 > div + div dl {
          border-bottom: 1px solid #ebebeb;
          font-size: 0;
          padding: 0 0 14px;
          display: table;
          width: 100%; }
          #PageAbout.pageIndex #Main #ContBox03 > div + div dl + dl {
            padding-top: 15px; }
          #PageAbout.pageIndex #Main #ContBox03 > div + div dl dt, #PageAbout.pageIndex #Main #ContBox03 > div + div dl dd {
            display: table-cell;
            vertical-align: top;
            font-size: 18px;
            line-height: 29px; }
          #PageAbout.pageIndex #Main #ContBox03 > div + div dl dt {
            font-weight: 700;
            width: 110px; }
        #PageAbout.pageIndex #Main #ContBox03 > div + div p {
          font-size: 16px;
          line-height: 27px;
          color: #777777;
          margin-top: 14px; }
  /*
  お問い合わせ sp
  -------------------------------------*/
  #PageContact.pageIndex #Main,
  #PageContact.pageConfirm #Main {
    margin-bottom: 95px; }
    #PageContact.pageIndex #Main #ContBox01 .tel,
    #PageContact.pageConfirm #Main #ContBox01 .tel {
      color: #444; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox01,
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 {
      padding: 0 15px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 p + div,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 p + div {
        line-height: 29px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 p + div a,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 p + div a {
          color: #559ad3;
          font-size: 14px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form {
        margin-top: 41px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl {
          width: 100%;
          border-bottom: 1px solid #ebebeb;
          padding: 25px 0 30px;
          color: #444; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl:first-of-type,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl:first-of-type {
            border-top: 1px solid #ebebeb;
            padding: 24px 0 31px; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl:first-of-type dd:last-of-type,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl:first-of-type dd:last-of-type {
              margin-top: 0; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dt, #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dt,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd {
            font-size: 18px;
            display: inline-block;
            vertical-align: middle; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dt,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dt {
            font-weight: 700;
            line-height: 29px;
            margin-left: 15px; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type {
            font-size: 12px;
            font-weight: 700;
            line-height: 29px; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span {
              color: #858585; }
              #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span.required,
              #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span.required {
                color: #d55c5c; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child {
            display: block;
            margin-top: 8px; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"],
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"],
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"],
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"],
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"],
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"],
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea {
              width: 100%;
              height: 50px;
              border: 1px solid #c0bfbd;
              background: #f8f7f6;
              padding-left: 10px; }
              #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"]:focus,
              #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"]:focus,
              #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"]:focus,
              #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea:focus,
              #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"]:focus,
              #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"]:focus,
              #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"]:focus,
              #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea:focus {
                box-shadow: 1px 1px 1px #0979db, -1px -1px 1px #0979db; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child input::placeholder,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child input::placeholder {
              color: #adadad; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea::placeholder,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea::placeholder {
              color: #adadad; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea {
              width: 100%;
              height: 250px;
              padding: 13px 15px 13px 10px;
              resize: none;
              line-height: 29px; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span {
              width: 34px;
              height: 34px;
              margin-right: 5px;
              background: transparent url(../img/contents/radio01.png) no-repeat scroll left center;
              background-size: 34px 34px; }
              #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span.checked,
              #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span.checked {
                background: transparent url(../img/contents/radio02.png) no-repeat scroll left center;
                background-size: 34px 34px; }
              #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span input,
              #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child .radio span input {
                width: 34px;
                height: 34px; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form dl dd:last-child label,
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child label {
              display: inline-block;
              line-height: 34px;
              margin-top: 13px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul {
          position: relative;
          width: 100%;
          margin: 35px 0 0; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul li.reset,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul li.reset {
            margin-top: 37px;
            text-align: center; }
            #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"],
            #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"] {
              opacity: 1;
              color: #559ad3;
              font-size: 14px;
              text-decoration: underline; }
          #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 form ul li #image-btn,
          #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form ul li #image-btn {
            display: block;
            width: 100%;
            height: 72px;
            margin: 0px auto;
            border-radius: 7px;
            opacity: 1;
            color: #fff;
            font-size: 26px;
            font-weight: 700;
            line-height: 72px;
            letter-spacing: 0.5px;
            background: #6ea7d8; }
    #PageContact.pageIndex #Main #ContBox01 #ContSubBox02,
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 {
      margin-top: 110px;
      padding: 70px 15px 0;
      border-top: 1px solid #eeeeee; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 h3,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 h3 {
        margin-bottom: 32px;
        letter-spacing: -1.5px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 h4,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 h4 {
        font-size: 26px;
        line-height: 37px;
        font-weight: 700;
        margin: 28px 0 17px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 p + p,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 p + p {
        margin-top: 10px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 ul li,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 ul li {
        font-size: 18px;
        line-height: 29px; }
      #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 dl,
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 dl {
        margin-top: 10px;
        font-size: 18px;
        line-height: 29px; }
        #PageContact.pageIndex #Main #ContBox01 #ContSubBox02 dl dt,
        #PageContact.pageConfirm #Main #ContBox01 #ContSubBox02 dl dt {
          font-weight: 700; }
  /*
  お問い合わせ内容確認 sp
  -------------------------------------*/
  #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form#form1 dl {
    padding: 25px 0 23px; }
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form#form1 dl dd:last-of-type {
      margin-top: 7px;
      line-height: 29px; }
  /*
  お問い合わせ送信完了 sp
  -------------------------------------*/
  #PageContact.pageThanks #Main #ContBox01 {
    margin-bottom: 110px; }
    #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 {
      padding: 0 15px; }
      #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 p + div {
        width: 100%;
        height: 72px;
        line-height: 72px;
        text-align: center;
        margin: 30px auto 0; }
        #PageContact.pageThanks #Main #ContBox01 #ContSubBox01 p + div a {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 7px;
          border: 1px solid #d3d3d3;
          font-size: 26px;
          font-weight: 700;
          color: #949494;
          background: #e3e3e3;
          text-decoration: none; } }
