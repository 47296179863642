@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.02
CSS 担当者名 Document 2018.02
Last Update 2018.02 担当者名
---------------------------------*/
@media print,
screen and (min-width: 768px) {

  /*
  404
  -------------------------------------*/
  #Page404 #Main{
    #ContBox01{
      #ContSubBox01{
      }
      #ContSubBox02{
      }
    }
  }
  /*
  トップページ 施設 共通  pc
  -------------------------------------*/
  #PageIndex.pageIndex #Main #ContBox02,
  #PageFacility.pageIndex #Main #ContBox01,
  #PageFacility.pageCategory #Main #ContBox01{
    .searchTitle{
      font-size:18px;
      font-weight:700;
      line-height:40px;
      margin-bottom:8px;
      letter-spacing:2px;
      color:#444;
      text-align:left;
      span{
        color:#4391d3;
      }
    }
    div{
      &.left{
        width:320px;
        float:left;
      }
      &.right{
        width:630px;
        float:right;
      }
      .search{
        width:100%;
        border:3px solid #ededed;
        border-radius:6px;
        ul{
          li{
            width:100%;
            padding:11px 15px 13px 45px;
            background:url(../img/menu_arrow02.png) no-repeat top 19px left 16px;
            background-size:19px auto;
            &+li{
              border-top:1px solid #ebebeb;
            }
            a{
              font-size:20px;
              line-height:34px;
              color:#444;
              text-decoration:none;
              &:hover{
                opacity:0.8;
              }
            }
            &.current,&.current-cat{
              a{
                color:#a3a3a3;
              }
            }
          }
        }
      }
    }
    #ContSubBox01{
      .searchTitle{
        background:url(../img/index_icon01.png) no-repeat center left;
        background-size:26px auto;
        padding-left:35px;
      }
      .link02{
        margin:16px 0 48px;
        letter-spacing:1px;
      }
    }
    #ContSubBox02{
      .searchTitle{
        background:url(../img/index_icon02.png) no-repeat center left;
        background-size:26px auto;
        padding-left:35px;
        letter-spacing:0.5px;
      }
      .search{
        ul{
          li{
            padding-left:50px;
          }
        }
      }
    }
    #ContSubBox03{
      .searchTitle{
        background:url(../img/index_icon03.png) no-repeat center left;
        background-size:33px auto;
        padding-left:40px;
      }
      #Gmap01{
        width:100%;
        height:490px;
      }
      .mapName{
        a{
          text-decoration:none;
          font-size:14px;
          &:hover{
            text-decoration:underline;
          }
        }
      }
    }
  }
  /*
  トップページ pc
  -------------------------------------*/
  #PageIndex.pageIndex #Main{
    margin-bottom:94px;
    #ContBox01{
      div{
        &.img{
          width:180px;
          margin:0 31px 0 80px;
          background:url(../img/index_iimg01.png) no-repeat top left;
          background-size:180px auto;
          padding-top:187px;
          text-align:center;
        }
        &.txt{
          width:645px;
          padding-top:20px;
          text-align:right;
          dl{
            text-align:left;
          }
          .link{
            margin-top:28px;
            margin-right:7px;
          }
        }
      }
    }
    #ContBox02{
      margin-top:70px;
      .innerBasic{
        &:after {
            content: "";
            display: block;
            visibility: hidden;
            clear: both;
            height: 0;
        }
      }
    }
    #ContBox03{
      border-top:1px solid #eee;
      margin-top:100px;
      padding-top:73px;
      h2{
        color:#444;
        font-size:28px;
        font-weight:700;
        line-height:40px;
        background:url(../img/index_icon04.png) no-repeat center left;
        background-size:32px auto;
        padding-left:40px;
      }
      .title{
        position:relative;
        .link{
          position:absolute;
          right:0;
          top:0;
          line-height:40px;
        }
      }
      ul.blogList{
        margin-top:22px;
        font-size:0;
        >li{
          width:300px;
          display:inline-block;
          vertical-align: top;
          &+li{
            margin-left:50px;
          }
          figure{
            width:100%;
            height:185px;
            overflow: hidden;
            a{
              display:block;
              width:100%;
              height:100%;
              &:hover{
                opacity: 0.8;
              }
            }
            img{
              width:100%;
              height:auto;
            }
          }
          dl{
            margin-top:20px;
            dd{
              font-size:16px;
              color:#a9905e;
              //display:inline-block;
              //vertical-align:middle;
              ul{
                display:table;
                width:100%;
                li{
                  display:table-cell;
                  vertical-align:top;
                  line-height:22px;
                  text-align:left;
                }
              }
              .date{
                border-right:1px solid #e1e0dd;
                padding-right:10px;
                letter-spacing:1px;
                width:105px;
              }
              .category{
                padding-left:12px;
              }
            }
            dt{
              margin-top:10px;
              font-size:18px;
              line-height:30px;
              a{
                color:#444;
                text-decoration:none;
                &:hover{
                  text-decoration:underline;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  ご入居までの流れ pc
  -------------------------------------*/
  #PageInfo.pageIndex #Main{
    #ContBox01{
      margin-bottom:95px;
      #ContSubBox01{
        ol{
          list-style: none;
          >li{
            background:url(../img/contents/flow_line.png) repeat-x top left;
            background-size:3px auto;
            >div{
              padding:58px 0 60px 47px;
              position:relative;
              &:before{
                content:"";
                display:block;
                width:2px;
                height:100%;
                background:#9cc4e7;
                position:absolute;
                left:7px;
                top:0;
                z-index:5;
              }
              >dl{
                >dt{
                  font-size:40px;
                  font-weight:bold;
                  color:#4391d3;
                  line-height:44px;
                  margin-bottom:29px;
                  position:relative;
                  letter-spacing:2px;
                  &:before{
                    content:"";
                    display:block;
                    width:16px;
                    height:16px;
                    background:#9cc4e7;
                    border-radius:50%;
                    position:absolute;
                    top:0;
                    bottom:0;
                    margin:auto;
                    left:-47px;
                    z-index:10;
                  }
                }
                >dd{
                  font-size:20px;
                  line-height:34px;
                  padding-left:4px;
                }
              }
              ul.contactInfo{
                margin-top:20px;
                li{
                  vertical-align:top;
                }
                li.tel{
                  font-size:50px;
                  dl{
                    dt{
                      font-size:50px;
                      background-size:54px auto;
                      padding-left:60px;
                      a{
                        color:#444;
                        cursor:default;
                        text-decoration:none;
                      }
                    }
                    dd{
                      font-size:18px;
                      text-align:center;
                    }
                  }
                }
                li.contact{
                  width:360px;
                  height:74px;
                  line-height:74px;
                  margin-left:35px;
                  letter-spacing:1px;
                  text-align:center;
                  a{
                    font-size:26px;
                    &:before{
                      width:40px;
                      height:74px;
                      background-size:40px auto;
                      position:relative;
                      left:-7px;
                    }
                  }
                }
              }
            }
            &:first-child{
              >div{
                position:relative;
                &:after{
                  content:"";
                  display:block;
                  width:2px;
                  height:82px;
                  background:#fff;
                  position:absolute;
                  left:7px;
                  top:0;
                  z-index:8;
                }
              }
            }
            &:nth-child(n+2){
              >div{
                min-height:318px;
                >dl{
                  width:680px;
                }
              }
            }
            &:nth-child(2){
              >div{
                background:url(../img/contents/flow_img01.png) no-repeat right center;
                background-size:238px auto;
              }
            }
            &:nth-child(3){
              >div{
                background:url(../img/contents/flow_img02.png) no-repeat right center;
                background-size:238px auto;
              }
            }
            &:nth-child(4){
              >div{
                background:url(../img/contents/flow_img03.png) no-repeat right center;
                background-size:238px auto;
              }
            }
            &:nth-child(5){
              >div{
                background:url(../img/contents/flow_img04.png) no-repeat right center;
                background-size:238px auto;
              }
            }
            &:last-child{
              background:url(../img/contents/flow_line.png) repeat-x top left,
              url(../img/contents/flow_line.png) repeat-x bottom left;
              background-size:3px auto;
              >div{
                min-height:auto;
                padding-bottom:53px;
                &:before{
                  height:82px;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  施設 共通 pc
  -------------------------------------*/
  #PageFacility.pageIndex #Main,
  #PageFacility.pageCategory #Main{
    margin-bottom:180px;
    #ContBox01{
      &:after {
          content: "";
          display: block;
          visibility: hidden;
          clear: both;
          height: 0;
      }
    }
    #ContBox02{
      margin-top:100px;
      h3{
        font-size:30px;
        line-height:34px;
        margin-bottom:75px;
      }
      .newList{
        font-size:0;
        li{
          width:475px;
          display:inline-block;
          vertical-align:top;
          &:nth-child(2n){
            margin-left:50px;
          }
          &:nth-child(n+3){
            margin-top:100px;
          }
          figure{
            height:290px;
            overflow:hidden;
            a{
              display:block;
              width:100%;
              height:100%;
              &:hover{
                opacity:0.8;
              }
            }
            img{
              width:100%;
              height:auto;
            }
          }
          >div{
            >dl{
              margin-top:18px;
              color:#444;
              >dd{
                &.type{
                  font-size:18px;
                  line-height:29px;
                  font-weight:700;
                }
                &.info{
                  //border:1px solid #dfdfdf;
                  margin-top:18px;
                  dl{
                    display:table;
                    width:100%;
                    font-size:18px;
                    line-height:30px;
                    border-right:1px solid #dfdfdf;
                    border-left:1px solid #dfdfdf;
                    &:first-of-type{
                      border-top:1px solid #dfdfdf;
                    }
                    &:last-of-type{
                      border-bottom:1px solid #dfdfdf;
                    }
                    &+dl{
                      background:url(../img/contents/flow_line.png) repeat-x top left;
                      background-size:3px auto;
                      //border-top:1px dotted #c2c2c2;
                      dt{
                        background:#f1f9fd url(../img/contents/flow_line.png) repeat-x top left;
                        background-size:3px auto;
                      }
                    }
                    dt,dd{
                      display:table-cell;
                      vertical-align:top;
                      padding:10px 20px;
                    }
                    dt{
                      font-weight:700;
                      background:#f1f9fd;
                      width:150px;
                    }
                  }
                }
              }
              >dt{
                font-size:28px;
                line-height:38px;
                font-weight:700;
                letter-spacing:1.2px;
              }
            }
            div{
              width:100%;
              height:45px;
              line-height:45px;
              margin-top:20px;
              a{
                display:block;
                width:100%;
                height:100%;
                border:1px solid #6ea7d8;
                border-radius:6px;
                background:url(../img/menu_arrow01.png) no-repeat center right 18px;
                background-size:11px auto;
                color:#559ad3;
                font-size:16px;
                text-align:center;
                text-decoration:none;
                &:hover{
                  opacity:0.8;
                }
              }
            }
          }
        }
      }
    }
  }
  /*
  施設
  -------------------------------------*/
  #PageFacility.pageIndex #Main{
    #ContBox01{
    }
  }

  /*
  施設カテゴリアーカイブ（子カテゴリ）
  -------------------------------------*/
  #PageFacility.pageCategory #Main{
    #ContBox01{
      #ContSubBox01{
      }
      #ContSubBox02{
      }
    }
  }

  /*
  施設詳細 pc
  -------------------------------------*/
  #PageFacility.pageEntry #Main{
    margin-top:95px;
    margin-bottom:160px;
    #ContBox01{
      h2{
        font-size:50px;
        font-weight:700;
        line-height:62px;
        color:#444;
        border-bottom:3px solid #ede6d9;
        padding-bottom:20px;
        margin-top:5px;
        letter-spacing:2px;
      }
      span.type{
        font-size:30px;
        font-weight:700;
        line-height:42px;
        color:#444;
        display:block;
        letter-spacing:1.5px;
      }
      >ul{
        margin:20px 0 40px;
        font-size:0;
        li{
          display:inline-block;
          border:1px solid #dce5ec;
          border-radius:3px;
          background:#e9f4fe;
          color:#4391d3;
          font-size:14px;
          font-weight:700;
          padding:0 4px;
          height:24px;
          line-height:24px;
          margin-right:8px;
          letter-spacing:1px;
        }
      }
      .contSubBox{
        margin-top:60px;
      }
      #ContSubBox01{
        margin-top:33px;
        &:after{
          content: "";
          display: block;
          visibility: hidden;
          clear: both;
          height: 0;
        }
        .imgBlock{
          width:560px;
          float:left;
          font-size:0;
          img{
            width:auto;
            height:100%;
          }
          ul{
            display:inline-block;
            vertical-align:top;
            width:60px;
            li{
              width:100%;
              height:60px;
              overflow:hidden;
              cursor:pointer;
              &+li{
                margin-top:2px;
              }
              a{
                display:block;
                width:100%;
                height:100%;
              }
              img{
                width:auto;
                height:100%;
              }
              &.current{
                opacity:0.2;
              }
              &:hover{
                opacity:0.2;
              }
            }
          }
          .mainImg{
            display:inline-block;
            vertical-align:top;
            width:496px;
            margin-left:4px;
            div{
              height:308px;
              overflow:hidden;
              position:relative;
              a{
                //display:inline-block;
                max-height:308px;
                //position:relative;
                &:after{
                  content:"";
                  display:block;
                  width:32px;
                  height:32px;
                  background:url(../img/contents/searchD_icon.png) no-repeat top left;
                  background-size:32px auto;
                  position:absolute;
                  right:10px;
                  bottom:10px;
                }
                &:hover{
                  opacity:0.8;
                }
              }
              img{
                max-width:100%;
                max-height: 100%;
                width:auto;
                height:auto;
              }
            }
            p{
              font-size:16px;
              text-align:center;
            }
          }
        }
        .infoBlock{
          width:390px;
          float:right;
          dl{
            background:url(../img/contents/flow_line.png) repeat-x left bottom;
            background-size:3px auto;
            font-size:20px;
            line-height:34px;
            display:table;
            width:100%;
            &:first-of-type{
              background:url(../img/contents/flow_line.png) repeat-x left bottom,
              url(../img/contents/flow_line.png) repeat-x left top;
              background-size:3px auto;
            }
            dt,dd{
              display:table-cell;
              vertical-align:top;
              padding:16px 0;
            }
            dt{
              width:108px;
              font-weight:700;
            }
          }
          dl+ul{
            margin-top:28px;
          }
          ul{
            font-size:0;
            //margin-top:28px;
            li{
              display:inline-block;
              width:60px;
              height:60px;
              //background:#fff8e9;
              //border:2px solid #dacaa9;
              //border-radius:6px;
              font-size:20px;
              line-height:22px;
              font-weight:700;
              color:#a9905e;
              margin-right:6px;
              &:nth-child(6n){
                margin-right:0;
              }
              &:nth-child(n+7){
                margin-top:6px;
              }
              img{
                width:100%;
                height:auto;
              }
              >span{
                display:table-cell;
                width:60px;
                height:60px;
                vertical-align:middle;
                text-align:center;
                b{
                  font-size:16px;
                }
                span{
                  letter-spacing:-2.5px;
                  b{
                    font-size:18px;
                  }
                }
              }
            }
          }
        }
      }
      #ContSubBox02{
        //border:1px solid #dfdfdf;
        dl{
          display:table;
          width:100%;
          font-size:18px;
          line-height:30px;
          border-right:1px solid #dfdfdf;
          border-left:1px solid #dfdfdf;
          &:first-of-type{
            border-top:1px solid #dfdfdf;
          }
          &:last-of-type{
            border-bottom:1px solid #dfdfdf;
          }
          &+dl{
            background:url(../img/contents/flow_line.png) repeat-x top left;
            background-size:3px auto;
            //border-top:1px dotted #c2c2c2;
            dt{
              background:#f1f9fd url(../img/contents/flow_line.png) repeat-x top left;
              background-size:3px auto;
            }
          }
          dt,dd{
            display:table-cell;
            vertical-align:top;
            padding:10px 20px;
          }
          dt{
            font-weight:700;
            background:#f1f9fd;
            width:220px;
          }
        }
      }
      #ContSubBox03{
        h3{
          text-align:left;
          font-size:30px;
          line-height:34px;
          margin-bottom:28px;
          letter-spacing:1.2px;
        }
        div{
          font-size:20px;
          line-height:34px;
          p{
            &+p{
              margin-top:20px;
            }
          }
        }
      }
      #ContSubBox04{
        background:#f1f9fd;
        border:1px solid #d6e2ed;
        padding:38px;
        font-size:0;
        display:table;
        width:100%;
        .imgBlock{
          display:table-cell;
          vertical-align:top;
          width:180px;
          height:180px;
          img{
            width:100%;
            height:auto;
          }
          &+.textBlock{
            padding-left:30px;
          }
        }
        .textBlock{
          display:table-cell;
          vertical-align:top;
          h3{
            font-size:24px;
            line-height:28px;
            margin-bottom:20px;
            text-align:left;
            letter-spacing:1.2px;
          }
          div{
            font-size:18px;
            line-height:30px;
            p{
              font-size:18px;
              line-height:30px;
              &+p{
                margin-top:10px;
              }
            }
          }
        }
      }
      #ContSubBox05{
        #Gmap02{
          width:100%;
          height:300px;
        }
        div.mapLink{
          background:url(../img/contents/menu_icon02.png) no-repeat center left;
          background-size:12px auto;
          display:table;
          padding-left:18px;
          line-height:24px;
          margin:10px 0 0 auto;
          a{
            font-size:14px;
            color:#559ad3;
            text-decoration:none;
            &:hover{
              opacity:0.8;
            }
          }
        }
      }
    }
  }

  /*
  ブログ一覧 共通 pc
  -------------------------------------*/
  #PageTopics{
    #Main{
      width:710px;
      float:right;
      margin-bottom:190px;
      #ContBox01{
        >h3{
          line-height:46px;
          text-align:left;
          letter-spacing:1.5px;
          margin-bottom:50px;
        }
        ul.blogList{
          font-size:0;
          padding-bottom:70px;
          border-bottom:1px solid #dedede;
          >li{
            width:330px;
            display:inline-block;
            vertical-align: top;
            &:nth-child(2n){
              margin-left:50px;
            }
            &:nth-child(n+3){
              margin-top:52px;
            }
            figure{
              width:100%;
              height:200px;
              overflow: hidden;
              a{
                display:block;
                width:100%;
                height:100%;
                &:hover{
                  opacity:0.8;
                }
              }
              img{
                width:100%;
                height:auto;
              }
            }
            dl{
              margin-top:20px;
              dd{
                font-size:16px;
                color:#a9905e;
                //display:inline-block;
                //vertical-align:middle;
                ul{
                  display:table;
                  width:100%;
                  li{
                    display:table-cell;
                    vertical-align:top;
                    line-height:20px;
                    text-align:left;
                  }
                }
                .date{
                  border-right:1px solid #e1e0dd;
                  padding-right:10px;
                  letter-spacing:1px;
                  //margin-right:12px;
                  width:105px;
                }
                .category{
                  padding-left:12px;
                  font-size:0;
                  span{
                    display:inline-block;
                    vertical-align:top;
                    margin-right:10px;
                    font-size:16px;
                  }
                }
              }
              dt{
                margin-top:8px;
                font-size:20px;
                line-height:34px;
                a{
                  color:#444;
                  text-decoration:none;
                  &:hover{
                    text-decoration:underline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  ブログ一覧
  -------------------------------------*/
  #PageTopics.pageIndex #Main{
    #ContBox01{
      #ContSubBox01{
      }
      #ContSubBox02{
      }
    }
  }

  /*
  ブログカテゴリアーカイブ
  -------------------------------------*/
  #PageTopics.pageCategory #Main{
    #ContBox01{
      #ContSubBox01{
      }
      #ContSubBox02{
      }
    }
  }

  /*
  ブログ記事詳細 pc
  -------------------------------------*/
  #PageTopics.pageEntry #Main{
    #ContBox01{
      >h3{
        color:#444;
        margin-bottom:12px;
        &+ul{
          display:table;
          width:100%;
          li{
            font-size:16px;
            color:#a9905e;
            //display:inline-block;
            //vertical-align:middle;
            display:table-cell;
            vertical-align:top;
            line-height:20px;
            text-align:left;
            &.date{
              border-right:1px solid #e1e0dd;
              padding-right:10px;
              letter-spacing:1px;
              width:105px;
            }
            &.category{
              padding-left:12px;
              font-size:0;
              span{
                display:inline-block;
                vertical-align:top;
                margin-right:10px;
                font-size:16px;
              }
            }
          }
        }
      }
      .entryBox{
        margin-top:38px;
        padding-bottom:70px;
        border-bottom:1px solid #dedede;
        img{
          margin-bottom:20px;
        }
        p{
          &+p{
            margin-top:20px;
          }
        }
      }
    }
  }

  /*
  運営会社 pc
  -------------------------------------*/
  #PageAbout.pageIndex{
    #MainTitle{
      padding-bottom:90px;
    }
  }
  #PageAbout.pageIndex #Main{
    margin-bottom:90px;
    #ContBox01{
      padding-top:20px;
      figure{
        img{
          width:100%;
          height:auto;
        }
      }
      .imgTxt{
        p{
          float:right;
          width:610px;
          margin-top:-6px;
        }
        figure{
          width:360px;
          float:left;
        }
        &+p{
          margin:17px 0 27px;
        }
      }
      .imgTxt02{
        div{
          width:670px;
          float:left;
          margin-top:-7px;
          p+p{
            margin-top:20px;
          }
        }
        figure{
          width:300px;
          float:right;
        }
      }
    }
    #ContBox02{
      margin-top:60px;
      &.introductionBox{
        padding:50px 0;
        .innerBasic{
          &:before{
            top:-64px;
            left:120px;
          }
        }
        .txt{
          display:block;
          padding-left:302px;
          background:url(../img/contents/unei_img03.png) no-repeat top left;
          background-size:270px auto;
          min-height:270px;
          dl{
            padding-top:20px;
          }
          p{
            text-align:left;
            margin-top:13px;
          }
        }
      }
    }
    #ContBox03{
      margin-top:80px;
      padding-top:20px;
      overflow:hidden;
      >div{
        &:first-of-type{
          width:470px;
          float:left;
          #AboutGmap{
            width:100%;
            height:470px;
            &+div{
              background:url(../img/contents/menu_icon02.png) no-repeat center left;
              background-size:12px auto;
              display:table;
              padding-left:17px;
              margin:10px 0 0 auto;
              a{
                font-size:14px;
                line-height:24px;
                color:#559ad3;
                text-decoration:none;
                &:hover{
                  opacity:0.8;
                }
              }
            }
          }
        }
        &+div{
          width:470px;
          float:right;
          dl{
            border-bottom:1px solid #ebebeb;
            font-size:0;
            padding:0 0 19px;
            display:table;
            width:100%;
            &+dl{
              padding-top:20px;
            }
            dt,dd{
              display:table-cell;
              vertical-align:top;
              font-size:20px;
              line-height:34px;
            }
            dt{
              font-weight:700;
              width:120px;
            }
          }
          p{
            font-size:18px;
            line-height:30px;
            color:#777777;
            margin-top:24px;
          }
        }
      }
    }
  }

  /*
  お問い合わせ pc
  -------------------------------------*/
  #PageContact.pageIndex #Main,
  #PageContact.pageConfirm #Main{
    margin-bottom:167px;
    #ContBox01{
      .tel{
        color:#444;
        text-decoration:none;
        cursor: default;
      }
      #ContSubBox01{
        p{
          text-align:center;
        }
        p+div{
          text-align:center;
          line-height:34px;
          a{
            color:#559ad3;
            font-size:16px;
          }
        }
        form{
          margin-top:50px;
          dl{
            width:100%;
            display: table;
            border-bottom:1px solid #ebebeb;
            padding:22px 0 21px;
            color:#444;
            &:first-of-type{
              border-top:1px solid #ebebeb;
              padding:30px 0 27px;
              dt,dd:first-of-type{
                padding-top:0;
              }
            }
            dt,dd{
              display:table-cell;
              vertical-align:top;
              font-size:20px;
            }
            dt{
              width:300px;
              font-weight:700;
              padding-top:8px;
              line-height:34px;
            }
            dd{
              &:first-of-type{
                width:60px;
                padding-top:8px;
                font-size: 14px;
                font-weight:700;
                line-height:34px;
                span{
                  color:#858585;
                  &.required{
                    color:#d55c5c;
                  }
                }
              }
              &:last-child{
                width:640px;
                input[type="text"],
                input[type="email"],
                input[type="tel"],
                textarea{
                  width:240px;
                  height:50px;
                  border:1px solid #c0bfbd;
                  background:#f8f7f6;
                  padding-left:14px;
                  &:focus{
                    box-shadow:1px 1px 1px #0979db, -1px -1px 1px #0979db;
                  }
                }
                input::placeholder {
                  color: #adadad;
                }
                textarea::placeholder {
                  color: #adadad;
                }
                #text4,#text5{
                  width:430px;
                }
                #text6,#text7{
                  width:218px;
                }
                #text8{
                  width:100%;
                }
                #text9{
                  width:490px;
                }
                textarea{
                  width:100%;
                  height:250px;
                  padding:10px 20px 10px 14px;
                  resize: none;
                  line-height:34px;
                }
                .radio{
                  span{
                    width: 34px;
                    height: 34px;
                    margin-right: 5px;
                    background: transparent url(../img/contents/radio01.png) no-repeat scroll left center;
                    background-size:34px 34px;
                    &.checked{
                      background: transparent url(../img/contents/radio02.png) no-repeat scroll left center;
                      background-size:34px 34px;
                    }
                    input{
                      width:34px;
                      height:34px;
                    }
                  }
                }
                label{
                  display:inline-block;
                  margin-right: 59px;
                  line-height: 34px;
                }
              }
            }
          }
          ul{
            position: relative;
            width:100%;
            margin:60px 0 0;
            li{
              &.reset{
                position:absolute;
                top:0;
                left:0;
                line-height:72px;
                input[type="button"]{
                  opacity: 1;
                  color: #559ad3;
                  font-size: 16px;
                  text-decoration: underline;
                  &:hover{
                    text-decoration:none;
                  }
                }
              }
              #image-btn{
                display: block;
                width: 368px;
                height: 72px;
                margin: 0px auto;
                border-radius: 7px;
                opacity: 1;
                color: #fff;
                font-size: 28px;
                font-weight:700;
                line-height: 72px;
                letter-spacing:0.5px;
                background:#6ea7d8;
                &:hover{
                  opacity:0.8;
                }
              }
            }
          }
        }
      }
      #ContSubBox02{
        margin-top:180px;
        padding-top:119px;
        border-top:1px solid #eeeeee;
        h3{
          margin-bottom:49px;
        }
        h4{
          font-size:28px;
          line-height:34px;
          font-weight:700;
          margin:47px 0 22px;
        }
        p+p{
          margin-top:19px;
        }
        ul{
          li{
            font-size:20px;
            line-height:34px;
          }
        }
        dl{
            margin-top:25px;
            font-size:20px;
            line-height:34px;
            dt{
              font-weight:700;
            }
        }
      }
    }
  }

  /*
  お問い合わせ内容確認 pc
  -------------------------------------*/
  #PageContact.pageConfirm #Main{
    #ContBox01{
      #ContSubBox01{
        h3{
          margin-bottom:47px;
        }
        form#form1{
          dl{
            padding: 30px 0 29px;
            dt,dd{
              padding-top:0;
            }
            dd{
              line-height:34px;
            }
          }
        }
      }
      #ContSubBox02{
      }
    }
  }

  /*
  お問い合わせ送信完了 pc
  -------------------------------------*/
  #PageContact.pageThanks #Main{
    #ContBox01{
      margin-bottom:190px;
      #ContSubBox01{
        p{
          text-align:center;
          &+div{
            width:368px;
            height:72px;
            line-height:72px;
            text-align:center;
            margin:30px auto 0;
            a{
              display:block;
              width:100%;
              height:100%;
              border-radius:7px;
              border:1px solid #d3d3d3;
              font-size:28px;
              font-weight:700;
              color:#949494;
              background:#e3e3e3;
              text-decoration:none;
              &:hover{
                opacity:0.8;
              }
            }
          }
        }
      }
    }
  }


}
@media print,
screen and (max-width: 767px) {
  /*
  404
  -------------------------------------*/
  #Page404 #Main{
    #ContBox01{
      #ContSubBox01{
      }
      #ContSubBox02{
      }
    }
  }
  /*
  トップページ 施設 共通  sp
  -------------------------------------*/
  #PageIndex.pageIndex #Main #ContBox02,
  #PageFacility.pageIndex #Main #ContBox01,
  #PageFacility.pageCategory #Main #ContBox01{
    padding:0 15px;
    .searchTitle{
      font-size:16px;
      font-weight:700;
      line-height:40px;
      margin-bottom:8px;
      letter-spacing:1px;
      color:#444;
      text-align:left;
      span{
        color:#4391d3;
      }
    }
    div{
      .search{
        width:100%;
        border:3px solid #ededed;
        border-radius:6px;
        ul{
          li{
            width:100%;
            padding:11px 20px 13px 45px;
            background:url(../img/menu_arrow02.png) no-repeat top 15px left 17px;
            background-size:19px auto;
            &+li{
              border-top:1px solid #ebebeb;
            }
            a{
              font-size:18px;
              line-height:29px;
              color:#444;
              text-decoration:none;
            }
            &.current,&.current-cat{
              a{
                color:#a3a3a3;
              }
            }
          }
        }
      }
    }
    #ContSubBox01{
      .searchTitle{
        background:url(../img/index_icon01.png) no-repeat center left 2px;
        background-size:26px auto;
        padding-left:42px;
      }
      .link02{
        margin:18px 0 28px;
        a{
          font-size:14px;
        }
      }
    }
    #ContSubBox02{
      .searchTitle{
        background:url(../img/index_icon02.png) no-repeat center left 2px;
        background-size:26px auto;
        padding-left:40px;
      }
    }
    #ContSubBox03{
      margin-top:28px;
      .searchTitle{
        background:url(../img/index_icon03.png) no-repeat center left;
        background-size:33px auto;
        padding-left:40px;
      }
      #Gmap01{
        width:100%;
        height:350px;
      }
      .mapName{
        a{
          text-decoration:underline;
          font-size:14px;
        }
      }
    }
  }
  /*
  トップページ sp
  -------------------------------------*/
  #PageIndex.pageIndex #Main{
    margin-bottom:70px;
    #ContBox01{
      div{
        &.img{
          width:135px;
          background:url(../img/index_iimg01.png) no-repeat top left;
          background-size:135px auto;
          padding-top:137px;
          text-align:center;
        }
        &.txt{
          padding-left:15px;
          text-align:right;
          dl{
            text-align:left;
            dd{
              text-align:justify;
            }
          }
          .link{
            display:inline-block;
            margin-top:22px;
          }
        }
      }
    }
    #ContBox02{
      margin-top:35px;
    }
    #ContBox03{
      border-top:1px solid #eee;
      margin-top:50px;
      padding:43px 15px 0;
      h2{
        color:#444;
        font-size:26px;
        font-weight:700;
        line-height:40px;
        background:url(../img/index_icon04.png) no-repeat center left;
        background-size:32px auto;
        padding-left:44px;
      }
      .title{
        position:relative;
        .link{
          position:absolute;
          right:0;
          top:0;
          line-height:40px;
          height:40px;
        }
      }
      ul.blogList{
        margin-top:25px;
        >li{
          display:table;
          width:100%;
          &+li{
            margin-top:25px;
          }
          figure{
            width:110px;
            height:68px;
            overflow: hidden;
            display:table-cell;
            vertical-align:top;
            a{
              display:block;
              width:110px;
              height:68px;
            }
            img{
              width:100%;
              height:auto;
            }
          }
          dl{
            display:table-cell;
            vertical-align:top;
            padding-left:15px;
            dd{
              font-size:14px;
              color:#a9905e;
              //display:inline-block;
              //vertical-align:middle;
              ul{
                display:table;
                width:100%;
                li{
                  display:table-cell;
                  vertical-align:top;
                  line-height:20px;
                  text-align:left;
                }
              }
              .date{
                border-right:1px solid #e1e0dd;
                padding-right:8px;
                letter-spacing:0.8px;
                width:95px;
              }
              .category{
                padding-left:9px;
              }
            }
            dt{
              margin-top:8px;
              font-size:16px;
              line-height:25px;
              a{
                color:#444;
                text-decoration:none;
              }
            }
          }
        }
      }
    }
  }

  /*
  ご入居までの流れ sp
  -------------------------------------*/
  #PageInfo.pageIndex #Main{
    #ContBox01{
      margin-bottom:85px;
      #ContSubBox01{
        padding:0 15px;
        ol{
          list-style: none;
          >li{
            background:url(../img/contents/flow_line.png) repeat-x top left;
            background-size:3px auto;
            >div{
              padding:48px 0 50px 28px;
              position:relative;
              &:before{
                content:"";
                display:block;
                width:2px;
                height:100%;
                background:#9cc4e7;
                position:absolute;
                left:7px;
                top:0;
                z-index:5;
              }
              >dl{
                >dt{
                  font-size:35px;
                  font-weight:bold;
                  color:#4391d3;
                  line-height:42px;
                  margin-bottom:18px;
                  position:relative;
                  &:before{
                    content:"";
                    display:block;
                    width:16px;
                    height:16px;
                    background:#9cc4e7;
                    border-radius:50%;
                    position:absolute;
                    top:12px;
                    left:-28px;
                    z-index:10;
                  }
                }
                >dd{
                  font-size:18px;
                  line-height:29px;
                  padding-left:2px;
                }
              }
              ul.contactInfo{
                margin-top:18px;
                li.tel{
                  max-width:295px;
                  dl{
                    display:table;
                    margin:0 auto;
                    dt{
                      white-space: nowrap;
                      padding-left:0;
                      background:none;
                      &:before{
                        content:"";
                        display:inline-block;
                        width:40px;
                        height:35px;
                        vertical-align:top;
                        background: url(../img/tel_icon.png) no-repeat center left;
                        background-size: 40px auto;
                        position:relative;
                        margin-right:6px;
                      }
                    }
                    dd{
                      text-align:center;
                    }
                  }
                }
                li.contact{
                  text-align:center;
                  margin:19px 0 0;
                  a{
                    border-radius:3px;
                  }
                }
              }
            }
            &:first-child{
              >div{
                position:relative;
                &:after{
                  content:"";
                  display:block;
                  width:2px;
                  height:70px;
                  background:#fff;
                  position:absolute;
                  left:7px;
                  top:0;
                  z-index:8;
                }
              }
            }
            &:nth-child(n+2){
              >div{
                >dl{
                  padding-bottom:265px;
                }
              }
            }
            &:nth-child(2){
              >div{
                >dl{
                  background:url(../img/contents/flow_img01.png) no-repeat bottom center;
                  background-size:239px auto;
                }
              }
            }
            &:nth-child(3){
              >div{
                >dl{
                  background:url(../img/contents/flow_img02.png) no-repeat bottom center;
                  background-size:239px auto;
                }
              }
            }
            &:nth-child(4){
              >div{
                >dl{
                  background:url(../img/contents/flow_img03.png) no-repeat bottom center;
                  background-size:239px auto;
                }
              }
            }
            &:nth-child(5){
              >div{
                >dl{
                  background:url(../img/contents/flow_img04.png) no-repeat bottom center;
                  background-size:239px auto;
                }
              }
            }
            &:last-child{
              background:url(../img/contents/flow_line.png) repeat-x top left,
              url(../img/contents/flow_line.png) repeat-x bottom left;
              background-size:3px auto;
              >div{
                >dl{
                  padding-bottom:0;
                }
                &:before{
                  height:70px;
                }
              }
            }
          }
        }
      }
    }
  }
  /*
  施設 共通 pc
  -------------------------------------*/
  #PageFacility.pageIndex #Main,
  #PageFacility.pageCategory #Main{
    margin-bottom:160px;
    #ContBox01{
    }
    #ContBox02{
      margin-top:78px;
      padding:0 15px;
      h3{
        font-size:28px;
        line-height:1;
        margin-bottom:59px;
      }
      .newList{
        li{
          vertical-align:top;
          &+li{
            margin-top:80px;
          }
          figure{
            overflow:hidden;
            a{
              display:block;
              width:100%;
              height:100%;
            }
            img{
              width:100%;
              height:auto;
            }
          }
          >div{
            >dl{
              margin-top:18px;
              color:#444;
              >dd{
                &.type{
                  font-size:16px;
                  line-height:19px;
                  font-weight:700;
                  margin-bottom:4px;
                }
                &.info{
                  //border:1px solid #dfdfdf;
                  margin-top:14px;
                  dl{
                    display:table;
                    width:100%;
                    font-size:16px;
                    line-height:27px;
                    border-right:1px solid #dfdfdf;
                    border-left:1px solid #dfdfdf;
                    &:first-of-type{
                      border-top:1px solid #dfdfdf;
                    }
                    &:last-of-type{
                      border-bottom:1px solid #dfdfdf;
                    }
                    &+dl{
                      background:url(../img/contents/flow_line.png) repeat-x top left;
                      background-size:3px auto;
                      dt{
                        background:#f1f9fd url(../img/contents/flow_line.png) repeat-x top left;
                        background-size:3px auto;
                      }
                    }
                    dt,dd{
                      display:table-cell;
                      vertical-align:top;
                      padding:10px;
                    }
                    dt{
                      font-weight:700;
                      background:#f1f9fd;
                      width:108px;
                    }
                  }
                }
              }
              >dt{
                font-size:26px;
                line-height:35px;
                font-weight:700;
                letter-spacing:1px;
              }
            }
            div{
              width:100%;
              height:45px;
              line-height:45px;
              margin-top:20px;
              a{
                display:block;
                width:100%;
                height:100%;
                border:1px solid #6ea7d8;
                border-radius:3px;
                background:url(../img/menu_arrow01.png) no-repeat center right 18px;
                background-size:11px auto;
                color:#559ad3;
                font-size:14px;
                text-align:center;
                text-decoration:none;
              }
            }
          }
        }
      }
    }
  }
  /*
  施設
  -------------------------------------*/
  #PageFacility.pageIndex #Main{
    #ContBox01{
    }
  }

  /*
  施設カテゴリアーカイブ（子カテゴリ） sp
  -------------------------------------*/
  #PageFacility.pageCategory #Main{
    #ContBox01{
      h3{
        line-height:42px;
        margin-bottom:40px;
      }
    }
  }

  /*
  施設詳細 sp
  -------------------------------------*/
  #PageFacility.pageEntry #Main{
    margin-top:47px;
    margin-bottom:75px;
    #ContBox01{
      padding:0 15px;
      h2{
        font-size:35px;
        font-weight:700;
        line-height:42px;
        color:#444;
        border-bottom:3px solid #ede6d9;
        padding-bottom:13px;
        margin-top:6px;
        letter-spacing:1.2px;
      }
      span.type{
        font-size:22px;
        font-weight:700;
        line-height:29px;
        color:#444;
        display:block;
        letter-spacing:1px;
      }
      >ul{
        margin:5px 0 34px;
        font-size:0;
        li{
          display:inline-block;
          border:1px solid #dce5ec;
          border-radius:3px;
          background:#e9f4fe;
          color:#4391d3;
          font-size:12px;
          font-weight:700;
          padding:0 4px;
          height:24px;
          line-height:24px;
          margin-right:8px;
          letter-spacing:0.5px;
          margin-top:10px;
        }
      }
      .contSubBox{
        margin-top:50px;
      }
      #ContSubBox01{
        margin-top:24px;
        .imgBlock{
          display:table;
          width:100%;
          table-layout: fixed;
          img{
            width:auto;
            height:100%;
          }
          ul{
            display:table-cell;
            vertical-align:top;
            width:37px;
            li{
              width:100%;
              height:37px;
              overflow:hidden;
              cursor:pointer;
              &+li{
                margin-top:1px;
              }
              img{
                width:auto;
                height:100%;
              }
              a{
                display:block;
                width:100%;
                height:100%;
              }
              &.current{
                opacity:0.2;
              }
            }
          }
          .mainImg{
            display:table-cell;
            vertical-align:top;
            padding-left:4px;
            >div{
              height:189px;
              overflow:hidden;
              position:relative;
              a{
                //display:inline-block;
                //position:relative;
                max-height:189px;
                &:after{
                  content:"";
                  display:block;
                  width:32px;
                  height:32px;
                  background:url(../img/contents/searchD_icon.png) no-repeat top left;
                  background-size:32px auto;
                  position:absolute;
                  right:5px;
                  bottom:5px;
                }
              }
              img{
                max-width:100%;
                max-height: 100%;
                width:auto;
                height:auto;
              }
            }
            p{
              font-size:14px;
              text-align:center;
            }
          }
        }
        .infoBlock{
          margin-top:23px;
          dl{
            background:url(../img/contents/flow_line.png) repeat-x left bottom;
            background-size:3px auto;
            font-size:18px;
            line-height:29px;
            display:table;
            width:100%;
            &:first-of-type{
              background:url(../img/contents/flow_line.png) repeat-x left bottom,
              url(../img/contents/flow_line.png) repeat-x left top;
              background-size:3px auto;
            }
            dt,dd{
              display:table-cell;
              vertical-align:top;
              padding:16px 0;
            }
            dt{
              width:108px;
              font-weight:700;
            }
          }
          ul{
            font-size:0;
            margin-top:22px;
            li{
              display:inline-block;
              width:60px;
              height:60px;
              //background:#fff8e9;
              //border:2px solid #dacaa9;
              //border-radius:6px;
              font-size:20px;
              line-height:22px;
              font-weight:700;
              color:#a9905e;
              margin-right:6px;
              margin-top:6px;
              img{
                width:100%;
                height:auto;
              }
              >span{
                display:table-cell;
                width:60px;
                height:60px;
                vertical-align:middle;
                text-align:center;
                b{
                  font-size:16px;
                }
                span{
                  letter-spacing:-2.5px;
                  b{
                    font-size:18px;
                  }
                }
              }
            }
          }
        }
      }
      #ContSubBox02{
        //border:1px solid #dfdfdf;
        dl{
          display:table;
          width:100%;
          font-size:16px;
          line-height:27px;
          border-right:1px solid #dfdfdf;
          border-left:1px solid #dfdfdf;
          &:first-of-type{
            border-top:1px solid #dfdfdf;
          }
          &:last-of-type{
            border-bottom:1px solid #dfdfdf;
          }
          &+dl{
            background:url(../img/contents/flow_line.png) repeat-x top left;
            background-size:3px auto;
            dt{
              background:#f1f9fd url(../img/contents/flow_line.png) repeat-x top left;
              background-size:3px auto;
            }
          }
          dt,dd{
            display:table-cell;
            vertical-align:top;
            padding:10px;
          }
          dt{
            font-weight:700;
            background:#f1f9fd;
            width:110px;
          }
        }
      }
      #ContSubBox03{
        h3{
          text-align:left;
          font-size:28px;
          line-height:34px;
          margin-bottom:13px;
          letter-spacing:1.2px;
        }
        div{
          font-size:18px;
          line-height:29px;
          p{
            &+p{
              margin-top:10px;
            }
          }
        }
      }
      #ContSubBox04{
        background:#f1f9fd;
        border:1px solid #d6e2ed;
        padding:30px 20px;
        font-size:0;
        .imgBlock{
          margin:0 auto 17px;
          width:180px;
          img{
            width:100%;
            height:auto;
          }
        }
        .textBlock{
          h3{
            font-size:22px;
            line-height:28px;
            margin-bottom:10px;
            text-align:left;
            letter-spacing:1px;
          }
          div{
            font-size:16px;
            line-height:27px;
            p{
              font-size:16px;
              line-height:27px;
              &+p{
                margin-top:6px;
              }
            }
          }
        }
      }
      #ContSubBox05{
        #Gmap02{
          width:100%;
          height:300px;
        }
        div.mapLink{
          background:url(../img/contents/menu_icon02.png) no-repeat center left;
          background-size:12px auto;
          display:table;
          padding-left:18px;
          line-height:24px;
          margin:10px 0 0 auto;
          a{
            font-size:12px;
            color:#559ad3;
            text-decoration:none;
          }
        }
      }
    }
  }

  /*
  ブログ一覧 共通 sp
  -------------------------------------*/
  #PageTopics{
    #Main{
      #ContBox01{
        padding:0 15px;
        >h3{
          line-height:42px;
          text-align:left;
          margin-bottom:40px;
          letter-spacing:1.5px;
        }
        ul.blogList{
          font-size:0;
          padding-bottom:80px;
          border-bottom:1px solid #dedede;
          >li{
            &+li{
              margin-top:44px;
            }
            figure{
              width:100%;
              a{
                display:block;
                width:100%;
                height:100%;
              }
              img{
                width:100%;
                height:auto;
              }
            }
            dl{
              margin-top:20px;
              dd{
                font-size:14px;
                color:#a9905e;
                //display:inline-block;
                //vertical-align:middle;
                ul{
                  display:table;
                  width:100%;
                  li{
                    display:table-cell;
                    vertical-align:top;
                    line-height:18px;
                    text-align:left;
                  }
                }
                .date{
                  border-right:1px solid #e1e0dd;
                  padding-right:10px;
                  letter-spacing:1px;
                  //margin-right:12px;
                  width:95px;
                }
                .category{
                  padding-left:12px;
                  font-size:0;
                  span{
                    display:inline-block;
                    vertical-align:top;
                    margin-right:10px;
                    font-size:14px;
                  }
                }
              }
              dt{
                margin-top:8px;
                font-size:18px;
                line-height:29px;
                a{
                  color:#444;
                  text-decoration:none;
                }
              }
            }
          }
        }
      }
    }
  }
  /*
  ブログ一覧
  -------------------------------------*/
  #PageTopics.pageIndex #Main{
    #ContBox01{
      #ContSubBox01{
      }
      #ContSubBox02{
      }
    }
  }

  /*
  ブログカテゴリアーカイブ
  -------------------------------------*/
  #PageTopics.pageCategory #Main{
    #ContBox01{
      #ContSubBox01{
      }
      #ContSubBox02{
      }
    }
  }

  /*
  ブログ記事詳細 sp
  -------------------------------------*/
  #PageTopics.pageEntry #Main{
    #ContBox01{
      >h3{
        color:#444;
        margin-bottom:12px;
        &+ul{
          display:table;
          width:100%;
          li{
            font-size:14px;
            color:#a9905e;
            //display:inline-block;
            //vertical-align:middle;
            display:table-cell;
            vertical-align:top;
            line-height:18px;
            text-align:left;
            &.date{
              border-right:1px solid #e1e0dd;
              padding-right:10px;
              letter-spacing:1px;
              width:95px;
            }
            &.category{
              padding-left:12px;
              font-size:0;
              span{
                display:inline-block;
                vertical-align:top;
                margin-right:10px;
                font-size:14px;
              }
            }
          }
        }
      }
      .entryBox{
        margin-top:30px;
        padding-bottom:45px;
        border-bottom:1px solid #dedede;
        img{
          margin-bottom:20px;
          width:100%;
          height:auto;
        }
        p{
          &+p{
            margin-top:10px;
          }
        }
      }
    }
  }

  /*
  運営会社 sp
  -------------------------------------*/
  #PageAbout.pageIndex #MainTitle{
    padding-bottom:42px;
  }
  #PageAbout.pageIndex #Main{
    margin-bottom:65px;
    #ContBox01{
      padding:20px 15px 0;
      figure{
        img{
          width:100%;
          height:auto;
        }
      }
      .imgTxt{
        figure{
          margin:25px 0;
        }
      }
      .imgTxt02{
        p{
          margin-top:10px;
        }
        figure{
          width:225px;
          margin:22px auto 0;
        }
      }
    }
    #ContBox02{
      margin-top:70px;
      &.introductionBox{
        padding:60px 15px;
        .innerBasic{
          display:block;
          &:before{
            top:-74px;
          }
        }
        .txt{
          display:block;
          padding-bottom:300px;
          background:url(../img/contents/unei_img03.png) no-repeat bottom center;
          background-size:271px auto;
          dl{
          }
          p{
            margin-top:14px;
            text-align:left;
            span{
              letter-spacing:0.5px;
            }
          }
        }
      }
    }
    #ContBox03{
      margin-top:49px;
      padding-top:20px;
      padding:0 15px;
      >div{
        &:first-of-type{
          #AboutGmap{
            width:100%;
            height:350px;
            &+div{
              background:url(../img/contents/menu_icon02.png) no-repeat center left;
              background-size:12px auto;
              display:table;
              padding-left:17px;
              margin:10px 0 0 auto;
              a{
                font-size:12px;
                line-height:20px;
                color:#559ad3;
                text-decoration:none;
              }
            }
          }
        }
        &+div{
          margin-top:38px;
          dl{
            border-bottom:1px solid #ebebeb;
            font-size:0;
            padding:0 0 14px;
            display:table;
            width:100%;
            &+dl{
              padding-top:15px;
            }
            dt,dd{
              display:table-cell;
              vertical-align:top;
              font-size:18px;
              line-height:29px;
            }
            dt{
              font-weight:700;
              width:110px;
            }
          }
          p{
            font-size:16px;
            line-height:27px;
            color:#777777;
            margin-top:14px;
          }
        }
      }
    }
  }

  /*
  お問い合わせ sp
  -------------------------------------*/
  #PageContact.pageIndex #Main,
  #PageContact.pageConfirm #Main{
    margin-bottom:95px;
    #ContBox01{
      .tel{
        color:#444;
      }
      #ContSubBox01{
        padding:0 15px;
        p+div{
          line-height:29px;
          a{
            color:#559ad3;
            font-size:14px;
          }
        }
        form{
          margin-top:41px;
          dl{
            width:100%;
            border-bottom:1px solid #ebebeb;
            padding:25px 0 30px;
            color:#444;
            &:first-of-type{
              border-top:1px solid #ebebeb;
              padding:24px 0 31px;
              dd:last-of-type{
                margin-top:0;
              }
            }
            dt,dd{
              font-size:18px;
              display:inline-block;
              vertical-align:middle;
            }
            dt{
              font-weight:700;
              line-height:29px;
              margin-left:15px;
            }
            dd{
              &:first-of-type{
                font-size: 12px;
                font-weight:700;
                line-height:29px;
                span{
                  color:#858585;
                  &.required{
                    color:#d55c5c;
                  }
                }
              }
              &:last-child{
                display:block;
                margin-top:8px;
                input[type="text"],
                input[type="email"],
                input[type="tel"],
                textarea{
                  width:100%;
                  height:50px;
                  border:1px solid #c0bfbd;
                  background:#f8f7f6;
                  padding-left:10px;
                  &:focus{
                    box-shadow:1px 1px 1px #0979db, -1px -1px 1px #0979db;
                  }
                }
                input::placeholder {
                  color: #adadad;
                }
                textarea::placeholder {
                  color: #adadad;
                }
                textarea{
                  width:100%;
                  height:250px;
                  padding:13px 15px 13px 10px;
                  resize: none;
                  line-height:29px;
                }
                .radio{
                  span{
                    width: 34px;
                    height: 34px;
                    margin-right: 5px;
                    background: transparent url(../img/contents/radio01.png) no-repeat scroll left center;
                    background-size:34px 34px;
                    &.checked{
                      background: transparent url(../img/contents/radio02.png) no-repeat scroll left center;
                      background-size:34px 34px;
                    }
                    input{
                      width:34px;
                      height:34px;
                    }
                  }
                }
                label{
                  display:inline-block;
                  line-height: 34px;
                  margin-top:13px;
                }
              }
            }
          }
          ul{
            position: relative;
            width:100%;
            margin:35px 0 0;
            li{
              &.reset{
                margin-top:37px;
                text-align:center;
                input[type="button"]{
                  opacity: 1;
                  color: #559ad3;
                  font-size: 14px;
                  text-decoration: underline;
                }
              }
              #image-btn{
                display: block;
                width: 100%;
                height: 72px;
                margin: 0px auto;
                border-radius: 7px;
                opacity: 1;
                color: #fff;
                font-size: 26px;
                font-weight:700;
                line-height: 72px;
                letter-spacing:0.5px;
                background:#6ea7d8;
              }
            }
          }
        }
      }
      #ContSubBox02{
        margin-top:110px;
        padding:70px 15px 0;
        border-top:1px solid #eeeeee;
        h3{
          margin-bottom:32px;
          letter-spacing:-1.5px;
        }
        h4{
          font-size:26px;
          line-height:37px;
          font-weight:700;
          margin:28px 0 17px;
        }
        p+p{
          margin-top:10px;
        }
        ul{
          li{
            font-size:18px;
            line-height:29px;
          }
        }
        dl{
            margin-top:10px;
            font-size:18px;
            line-height:29px;
            dt{
              font-weight:700;
            }
        }
      }
    }
  }

  /*
  お問い合わせ内容確認 sp
  -------------------------------------*/
  #PageContact.pageConfirm #Main{
    #ContBox01{
      #ContSubBox01{
        form#form1{
          dl{
            padding:25px 0 23px;
            dd:last-of-type{
              margin-top:7px;
              line-height:29px;
            }
          }
        }
      }
      #ContSubBox02{
      }
    }
  }

  /*
  お問い合わせ送信完了 sp
  -------------------------------------*/
  #PageContact.pageThanks #Main{
    #ContBox01{
      margin-bottom:110px;
      #ContSubBox01{
        padding:0 15px;
        p{
          &+div{
            width:100%;
            height:72px;
            line-height:72px;
            text-align:center;
            margin:30px auto 0;
            a{
              display:block;
              width:100%;
              height:100%;
              border-radius:7px;
              border:1px solid #d3d3d3;
              font-size:26px;
              font-weight:700;
              color:#949494;
              background:#e3e3e3;
              text-decoration:none;
            }
          }
        }
      }
    }
  }


}